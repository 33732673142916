.rubric-viewer {
  .proceed-section {
    position: relative; // Add this to contain the tooltip

    .proceed-btn {
      &.disabled {
        opacity: 0.6;
        cursor: not-allowed;
        background-color: #e0e0e0;
      }

      &[data-tooltip] {
        &:hover + .tooltip,
        &:focus + .tooltip {
          opacity: 1;
          transform: translateY(0);
          pointer-events: auto;
        }
      }
    }

    .tooltip {
      margin-top: 2.5rem;
      background: rgb(255, 255, 255);
      border-radius: 1.5rem;
      border: 1px solid rgba(228, 177, 122, 0.12);
      box-shadow: rgba(228, 177, 122, 0.06) 0px 2px 4px -2px,
                  rgba(228, 177, 122, 0.04) 0px 4px 8px -2px;
      position: absolute;
      bottom: calc(100% + 10px);
      left: 50%;
      transform: translateX(-50%) translateY(10px);
      padding: 1.5rem;
      font-size: 14px;
      white-space: pre-wrap;
      max-width: 400px;
      min-width: 300px;
      z-index: 1000;
      isolation: isolate;
      opacity: 0;
      pointer-events: none;
      transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
      animation: solutionFadeIn 0.5s ease-out forwards;
      color: #E4B17A;
      font-family: "Google Sans", system-ui, -apple-system, sans-serif;

      &::before {
        opacity: 1;
        content: "";
        position: absolute;
        inset: 0;
        background: linear-gradient(135deg, 
          rgba(253, 248, 243, 0.5) 0%, 
          rgba(255, 255, 255, 0.5) 50%, 
          rgba(253, 248, 243, 0.5) 100%);
        transition: opacity 0.4s;
        z-index: -1;
        border-radius: inherit;
      }

      &:hover {
        transform: translateX(-50%) translateY(-2px);
        border-color: rgba(228, 177, 122, 0.2);
        box-shadow: rgba(228, 177, 122, 0.08) 0px 12px 24px -6px,
                    rgba(228, 177, 122, 0.06) 0px 18px 36px -12px;
      }
    }
  }
}

@keyframes solutionFadeIn {
  0% { 
    opacity: 0; 
    transform: translateY(10px); 
  }
  100% { 
    opacity: 1; 
    transform: translateY(0px); 
  }
}

.highlight-missing-solution {
    position: relative;
    animation: highlightPulse 20s ease-in-out infinite;
    border: 1px solid #E4B17A;
    border-radius: 8px;
    transition: all 0.3s ease;

    &::before {
        content: "Fill in Solution";
        position: absolute;
        top: -8px;
        left: 16px;
        padding: 2px 8px;
        background-color: white;
        color: #E4B17A;
        font-size: 12px;
        font-weight: 500;
        border-radius: 4px;
        z-index: 1;
        box-shadow: 0 2px 4px rgba(228, 177, 122, 0.2);
        animation: labelPulse 20s ease-in-out infinite;
    }
}

@keyframes highlightPulse {
    0% {
        background-color: rgba(228, 177, 122, 0.05);
        box-shadow: 0 0 0 0 rgba(228, 177, 122, 0.8);
        transform: scale(1);
    }
    50% {
        background-color: rgba(228, 177, 122, 0.18);
        box-shadow: 0 0 30px 8px rgba(228, 177, 122, 0.25);
        transform: scale(1.005);
    }
    100% {
        background-color: rgba(228, 177, 122, 0.05);
        box-shadow: 0 0 0 0 rgba(228, 177, 122, 0.8);
        transform: scale(1);
    }
}

@keyframes labelPulse {
    0% {
        transform: translateY(0);
        box-shadow: 0 2px 4px rgba(228, 177, 122, 0.2);
        background-color: white;
    }
    50% {
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba(228, 177, 122, 0.4);
        background-color: rgba(228, 177, 122, 0.05);
    }
    100% {
        transform: translateY(0);
        box-shadow: 0 2px 4px rgba(228, 177, 122, 0.2);
        background-color: white;
    }
}

@keyframes tooltipFade {
    0%, 100% {
        opacity: 0;
        transform: translate(-50%, 10px);
    }
    20%, 80% {
        opacity: 1;
        transform: translate(-50%, 0);
    }
}

/* MCQ Solution Editor Styles */
/* MCQ Edit Button in QuestionsView */
.mcq-edit-button-container {
  margin-bottom: 8px;
  display: flex;
  justify-content: flex-end;
  
  .edit-mcq-button {
    background-color: #E4B17A;
    color: white;
    border: none;
    border-radius: 6px;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    transition: all 0.2s ease;
    box-shadow: 0 2px 4px rgba(228, 177, 122, 0.2);
    
    &:hover {
      background-color: darken(#E4B17A, 5%);
      transform: translateY(-1px);
      box-shadow: 0 4px 8px rgba(228, 177, 122, 0.3);
    }
    
    &:active {
      transform: translateY(0);
      box-shadow: 0 2px 4px rgba(228, 177, 122, 0.2);
    }
    
    &::before {
      content: '✏️';
      font-size: 14px;
    }
  }
}

.mcq-solution-editor {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  padding: 24px;
  margin: 16px;
  height: calc(100% - 32px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  
  .editor-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 24px;
    padding-bottom: 16px;
    border-bottom: 1px solid #e2e8f0;
    position: relative;
    
    h2 {
      font-size: 20px;
      font-weight: 600;
      color: #334155;
      margin: 0;
    }
    
    .question-info {
      flex: 1;
      margin-left: 24px;
      
      .question-id {
        font-weight: 600;
        color: #E4B17A;
        margin-bottom: 4px;
      }
      
      .question-text {
        font-size: 14px;
        color: #64748b;
        margin: 0;
        line-height: 1.4;
      }
    }
    
    .close-btn {
      background: none;
      border: none;
      color: #94a3b8;
      cursor: pointer;
      padding: 4px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease;
      
      &:hover {
        color: #475569;
        background-color: #f1f5f9;
      }
    }
  }
  
  .options-container {
    flex: 1;
    overflow-y: auto;
    padding: 0 8px;
    
    h3 {
      font-size: 16px;
      font-weight: 500;
      color: #334155;
      margin-bottom: 16px;
    }
    
    .mcq-option {
      background-color: #f8fafc;
      border-radius: 8px;
      padding: 16px;
      margin-bottom: 16px;
      border: 1px solid #e2e8f0;
      transition: all 0.2s ease;
      
      &:hover {
        border-color: #cbd5e1;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
      }
      
      .option-header {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        
        .option-letter {
          width: 32px;
          height: 32px;
          background-color: #E4B17A;
          color: white;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 600;
          margin-right: 12px;
        }
        
        .correct-checkbox {
          display: flex;
          align-items: center;
          cursor: pointer;
          user-select: none;
          margin-right: auto;
          
          input {
            margin-right: 8px;
            width: 16px;
            height: 16px;
            accent-color: #E4B17A;
          }
          
          span {
            font-size: 14px;
            color: #475569;
          }
        }
        
        .delete-option {
          background: none;
          border: none;
          color: #94a3b8;
          cursor: pointer;
          padding: 4px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.2s ease;
          
          &:hover {
            color: #ef4444;
            background-color: #fee2e2;
          }
        }
      }
      
      .option-text-input {
        width: 100%;
        padding: 10px 12px;
        border-radius: 6px;
        border: 1px solid #cbd5e1;
        font-size: 14px;
        transition: all 0.2s ease;
        
        &:focus {
          outline: none;
          border-color: #E4B17A;
          box-shadow: 0 0 0 3px rgba(228, 177, 122, 0.2);
        }
        
        &::placeholder {
          color: #94a3b8;
        }
      }
    }
    
    .add-option-btn {
      width: 100%;
      padding: 10px;
      border: 1px dashed #cbd5e1;
      background-color: #f8fafc;
      color: #64748b;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      transition: all 0.2s ease;
      margin-bottom: 24px;
      
      &:hover {
        background-color: #f1f5f9;
        border-color: #94a3b8;
        color: #475569;
      }
    }
  }
  
  .controls {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    padding-top: 16px;
    border-top: 1px solid #e2e8f0;
    
    button {
      padding: 10px 16px;
      border-radius: 6px;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.2s ease;
    }
    
    .cancel-btn {
      background-color: white;
      border: 1px solid #cbd5e1;
      color: #475569;
      
      &:hover {
        background-color: #f1f5f9;
        border-color: #94a3b8;
      }
    }
    
    .save-btn {
      background-color: #E4B17A;
      border: 1px solid #E4B17A;
      color: white;
      
      &:hover {
        background-color: darken(#E4B17A, 5%);
        box-shadow: 0 2px 8px rgba(228, 177, 122, 0.4);
      }
    }
  }
}

/* Add and Delete Question/Subquestion Styles */
.delete-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: white;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  color: #ef4444;
  transition: all 0.2s ease;
  margin-left: 8px;
  
  &:hover {
    background-color: #fef2f2;
    border-color: #fecaca;
    color: #dc2626;
    transform: translateY(-1px);
    box-shadow: 0 2px 5px rgba(239, 68, 68, 0.15);
  }
  
  &:active {
    transform: translateY(0);
    box-shadow: none;
  }
  
  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.subquestion-header-edit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  
  label {
    font-weight: 500;
    color: #334155;
    font-size: 14px;
  }
  
  .delete-subquestion-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background-color: white;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    color: #ef4444;
    transition: all 0.2s ease;
    
    &:hover {
      background-color: #fef2f2;
      border-color: #fecaca;
      color: #dc2626;
    }
    
    &:active {
      transform: translateY(1px);
    }
  }
}

.add-subquestion-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  padding: 12px;
  margin-top: 16px;
  background: linear-gradient(135deg, rgba(253, 248, 243, 0.4), rgba(255, 255, 255, 0.7));
  border: 1px solid rgba(228, 177, 122, 0.25);
  border-radius: 10px;
  color: #E4B17A;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.01em;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  box-shadow: 
    0 2px 4px rgba(228, 177, 122, 0.05),
    0 1px 2px rgba(228, 177, 122, 0.08);
  position: relative;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.05));
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 1;
  }
  
  &:hover {
    background: linear-gradient(135deg, rgba(253, 248, 243, 0.7), rgba(255, 255, 255, 0.8));
    border-color: rgba(228, 177, 122, 0.4);
    color: darken(#E4B17A, 5%);
    transform: translateY(-1px);
    box-shadow: 
      0 3px 8px rgba(228, 177, 122, 0.1),
      0 6px 16px rgba(228, 177, 122, 0.06);
      
    &::before {
      opacity: 1;
    }
  }
  
  &:active {
    transform: translateY(0);
    box-shadow: 
      0 1px 3px rgba(228, 177, 122, 0.06),
      0 1px 2px rgba(228, 177, 122, 0.08);
  }
  
  svg {
    width: 16px;
    height: 16px;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    position: relative;
    z-index: 2;
  }
  
  span {
    position: relative;
    z-index: 2;
  }
  
  &:hover svg {
    transform: scale(1.1) rotate(90deg);
  }
  
  &:active svg {
    transform: scale(0.95);
  }
}

.add-question-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  padding: 16px;
  margin-top: 24px;
  margin-bottom: 12px;
  background: linear-gradient(135deg, rgba(253, 248, 243, 0.5), rgba(255, 255, 255, 0.8));
  border: 1px solid rgba(228, 177, 122, 0.3);
  border-radius: 12px;
  color: #E4B17A;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.01em;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  box-shadow: 
    0 2px 6px rgba(228, 177, 122, 0.06),
    0 1px 3px rgba(228, 177, 122, 0.1);
  position: relative;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.05));
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 1;
  }
  
  &:hover {
    background: linear-gradient(135deg, rgba(253, 248, 243, 0.8), rgba(255, 255, 255, 0.9));
    border-color: rgba(228, 177, 122, 0.5);
    color: darken(#E4B17A, 5%);
    transform: translateY(-2px);
    box-shadow: 
      0 4px 12px rgba(228, 177, 122, 0.12),
      0 8px 24px rgba(228, 177, 122, 0.08);
      
    &::before {
      opacity: 1;
    }
  }
  
  &:active {
    transform: translateY(0);
    box-shadow: 
      0 2px 4px rgba(228, 177, 122, 0.08),
      0 1px 2px rgba(228, 177, 122, 0.1);
  }
  
  svg {
    width: 18px;
    height: 18px;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    position: relative;
    z-index: 2;
  }
  
  span {
    position: relative;
    z-index: 2;
  }
  
  &:hover svg {
    transform: scale(1.1) rotate(90deg);
  }
  
  &:active svg {
    transform: scale(0.95);
  }
}

.no-questions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 60px 20px;
  text-align: center;
  
  svg {
    color: #94a3b8;
    margin-bottom: 8px;
  }
  
  p {
    font-size: 18px;
    font-weight: 500;
    color: #334155;
    margin: 0;
  }
  
  .help-text {
    font-size: 14px;
    color: #64748b;
    margin-bottom: 24px;
  }
  
  .add-question-btn {
    max-width: 280px;
    background: linear-gradient(135deg, rgba(253, 248, 243, 0.7), rgba(255, 255, 255, 0.9));
    box-shadow: 
      0 4px 12px rgba(228, 177, 122, 0.1),
      0 8px 24px rgba(228, 177, 122, 0.06);
  }
}

/* Scrollable PDF Viewer Styles */
.pdf-scroll-container {
  overflow-y: auto;
  height: 100%;
  background-color: #f6f8fa;
  scrollbar-width: thin;
  scrollbar-color: #ccc #f0f0f0;
  padding: 20px;
  
  &::-webkit-scrollbar {
    width: 8px;
  }
  
  &::-webkit-scrollbar-track {
    background: #f0f0f0;
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
    border: 2px solid #f0f0f0;
  }
}

.pdf-pages-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 10px 0;
}

.pdf-page {
  position: relative;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 5px;
  width: fit-content;
  max-width: 100%;
  margin: 0 auto;
  
  canvas {
    display: block;
    max-width: 100%;
    height: auto;
  }
}

.pdf-page-number {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  padding: 2px 8px;
  font-size: 12px;
  color: #64748b;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.pdf-loading-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  color: #334155;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.loading-spinner {
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 3px solid rgba(228, 177, 122, 0.2);
  border-radius: 50%;
  border-top-color: #E4B17A;
  animation: spin 1s ease-in-out infinite;
  margin-right: 10px;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}