// General styles for RenderBatch component
.render-batch-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #f9fafb;
    height: 100%;
}

.render-batch-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 900px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
    overflow: hidden;
    transition: transform 0.3s ease;

    &:hover {
        transform: translateY(-5px);
    }
}

.title {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: #333;
}

.info-text {
    font-size: 0.875rem;
    color: #666;
    line-height: 1.5;
    font-style: italic;
    margin-bottom: 1.5rem;
}

.exam-description {
    font-size: 1rem;
    color: #333;
    margin-bottom: 1.5rem;
}

.pdf-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
}

.pdf-viewer {
    width: 60%;
    height: 500px;
    border: none;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;

    &:hover {
        box-shadow: 0 6px 14px rgba(0, 0, 0, 0.2);
    }
}

.no-pdf-text {
    font-size: 0.875rem;
    color: #aaa;
    text-align: center;
}

.hidden {
    display: none;
}

@media (max-width: 768px) {
    .render-batch-container {
        padding: 15px;
    }

    .render-batch-content {
        padding: 15px;
    }

    .pdf-viewer {
        width: 90%;
        height: 400px;
    }
}
