.right-panel {
  width: 320px;
  background: white;
  border-left: 1px solid rgba(226, 232, 240, 0.8);
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: -4px 0 16px rgba(148, 163, 184, 0.05);
  transition: all 0.3s ease;

  .panel-header {
    padding: 1.25rem;
    border-bottom: 1px solid rgba(226, 232, 240, 0.8);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.95));
    backdrop-filter: blur(8px);
    position: sticky;
    top: 0;
    z-index: 10;

    h2 {
      font-size: 0.9375rem;
      font-weight: 600;
      color: #1e293b;
      letter-spacing: -0.01em;
      text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    }

    .page-info {
      font-size: 0.875rem;
      color: #64748b;
      font-weight: 500;
      display: flex;
      align-items: center;
      background: #f8fafc;
      padding: 0.375rem 0.75rem;
      border-radius: 1rem;
      border: 1px solid #e2e8f0;
      transition: all 0.2s ease;

      &:hover {
        border-color: #cbd5e1;
        background: #f1f5f9;
      }

      .separator {
        margin: 0 0.375rem;
        color: #94a3b8;
        font-weight: 400;
      }
    }
  }

  .page-controls {
    padding: 1rem;
    display: flex;
    gap: 0.75rem;
    border-bottom: 1px solid rgba(226, 232, 240, 0.8);
    flex-shrink: 0;
    background: #ffffff;

    .page-button {
      padding: 0.625rem;
      border-radius: 0.5rem;
      color: #64748b;
      transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
      border: 1px solid transparent;
      background: #f8fafc;

      &:hover:not(:disabled) {
        background: #f1f5f9;
        color: #1e293b;
        border-color: #e2e8f0;
        transform: translateY(-1px);
        box-shadow: 0 2px 4px rgba(148, 163, 184, 0.05);
      }

      &:active:not(:disabled) {
        transform: translateY(0);
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        background: #f8fafc;
      }

      svg {
        width: 18px;
        height: 18px;
        stroke-width: 2;
      }
    }

    .page-input-container {
      flex: 1;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        inset: 0;
        border-radius: 0.5rem;
        pointer-events: none;
        transition: all 0.2s ease;
      }

      input {
        width: 100%;
        padding: 0.625rem;
        border: 1px solid #e2e8f0;
        border-radius: 0.5rem;
        font-size: 0.875rem;
        text-align: center;
        color: #1e293b;
        font-weight: 500;
        transition: all 0.2s ease;
        background: #ffffff;

        &:hover {
          border-color: #cbd5e1;
        }

        &:focus {
          outline: none;
          border-color: #3b82f6;
          box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.12);
        }
      }
    }
  }

  .page-thumbnails {
    flex: 1;
    overflow-y: auto;
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    align-content: start;
    height: 0;
    background: linear-gradient(to bottom, #ffffff, #f8fafc);

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #cbd5e1;
      border-radius: 3px;
      transition: all 0.2s ease;

      &:hover {
        background-color: #94a3b8;
      }
    }

    .page-thumbnail {
      position: relative;
      padding-top: 141.4%;
      width: 100%;
      border: 1.5px solid #e2e8f0;
      border-radius: 0.75rem;
      overflow: hidden;
      transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
      background: white;
      cursor: pointer;
      transform-origin: center center;

      &:hover:not(:disabled) {
        border-color: #e4b17a;
        transform: scale(1.02) translateY(-2px);
        box-shadow: 0 4px 12px rgba(59, 130, 246, 0.12);
      }

      &:active:not(:disabled) {
        transform: scale(1) translateY(0);
      }

      &.active {
        border-color: #e4b17a;
        box-shadow: 0 0 0 2px rgba(228, 177, 122, 0.2);
        transform: scale(1.02);
      }

      &.has-selections {
        .selection-indicator {
          position: absolute;
          top: 0.5rem;
          right: 0.5rem;
          background: #e4b17a;
          color: white;
          font-size: 0.75rem;
          font-weight: 600;
          padding: 0.25rem 0.5rem;
          border-radius: 1rem;
          z-index: 1;
          box-shadow: 0 2px 4px rgba(59, 130, 246, 0.2);
          transition: all 0.2s ease;

          &:hover {
            transform: scale(1.05);
            box-shadow: 0 3px 6px rgba(59, 130, 246, 0.3);
          }
        }
      }

      .thumbnail-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;
        padding: 6px;

        .page-preview {
          width: 100%;
          height: 100%;
          object-fit: contain;
          background: white;
          border-radius: 0.5rem;
          transition: all 0.3s ease;
        }
      }

      .thumbnail-placeholder {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(135deg, #f8fafc, #f1f5f9);

        .page-number {
          font-size: 0.9375rem;
          color: #64748b;
          font-weight: 500;
          text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
        }
      }

      &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
        filter: grayscale(20%);
      }
    }
  }

  // Loading animation for thumbnails
  @keyframes shimmer {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }

  .thumbnail-loading {
    background: linear-gradient(
      90deg,
      #f1f5f9 25%,
      #e2e8f0 50%,
      #f1f5f9 75%
    );
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
  }
}

// Smooth transition animations
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.95);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
