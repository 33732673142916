.pdf-preview-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
  }

  .modal-content {
    position: relative;
    width: 90vw;
    height: 90vh;
    background: white;
    border-radius: 1rem;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .modal-header {
    padding: 1rem 1.5rem;
    border-bottom: 1px solid #e2e8f0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;

    h2 {
      font-size: 1.25rem;
      font-weight: 600;
      color: #1e293b;
    }

    .modal-actions {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    .download-btn {
      display: inline-flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.5rem 1rem;
      background: linear-gradient(135deg, #3b82f6, #2563eb);
      color: white;
      border: none;
      border-radius: 0.5rem;
      font-size: 0.875rem;
      font-weight: 500;
      cursor: pointer;
      text-decoration: none;
      transition: all 0.2s;

      &:hover {
        background: linear-gradient(135deg, #2563eb, #1d4ed8);
      }
    }

    .close-btn {
      padding: 0.5rem;
      border: none;
      background: none;
      color: #64748b;
      cursor: pointer;
      border-radius: 0.375rem;
      transition: all 0.2s;

      &:hover {
        background: #f1f5f9;
        color: #1e293b;
      }
    }
  }

  .modal-body {
    flex: 1;
    overflow: hidden;
    background: #f8fafc;
    padding: 1rem;

    .pdf-preview {
      width: 100%;
      height: 100%;
      border: none;
      border-radius: 0.5rem;
      background: white;
    }
  }
} 