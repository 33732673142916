// Move all your styles from src/app/style.scss to here

@font-face {
  font-family: "Google Sans";
  src: url("https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2vgCIhM907w.woff2") format("woff2");
}

* {
  font-family: "Google Sans", system-ui, -apple-system, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

// Base layout styles
.app {
  width: 100%;
  height: 100vh;
  background: #f8fafc;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

// Header styles
.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 2rem;
  background: white;
  border-bottom: 1px solid #e2e8f0;
  height: 73px;
  flex-shrink: 0;

  h1 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #1e293b;
    letter-spacing: -0.02em;
  }

  .secondary-button {
    display: inline-flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem 1.25rem;
    background: white;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    color: #8c6b4a;
    font-size: 0.875rem;
    font-weight: 500;
    text-decoration: none;
    transition: all 0.2s;

    &:hover {
      background: #fdf8f3;
      border-color: #e4b17a;
      color: #a17c57;
    }
  }
}

// Upload box styles
.upload-section {
  // max-width: 600px;
  width: 100%;
  padding: 2rem;
}

.upload-box {
  width: 100%;
  min-height: 320px;
  border: 2px dashed #e2e8f0;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  background: white;
  padding: 2rem;

  &:hover {
    border-color: #e4b17a;
    background: #fdf8f3;
    transform: translateY(-1px);
    box-shadow: 0 4px 12px -2px rgba(228, 177, 122, 0.2);
  }
}

.upload-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 2rem;
  text-align: center;
  width: 100%;

  .upload-icon {
    color: #94a3b8;
    margin-bottom: 1.5rem;
    transition: all 0.2s ease;
  }

  span {
    font-size: 1.25rem;
    font-weight: 500;
    color: #333;
    margin-bottom: 0.75rem;
    letter-spacing: -0.02em;
  }

  p {
    color: #64748b;
    font-size: 0.875rem;
    letter-spacing: -0.01em;
    line-height: 1.5;
    max-width: 80%;
    margin: 0 auto;
  }

  &:hover .upload-icon {
    color: #e4b17a;
    transform: translateY(-2px);
  }
}

.gradewiz-logo-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.5rem;
}

.gradewiz-logo-float {
  // position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.5rem;
  padding: 0px 1rem;

  h1 {
    font-size: 1.4rem;
    font-weight: 600;
    color: #1e293b;
    letter-spacing: -0.02em;
  }


}