@import url('https://fonts.googleapis.com/css2?family=Google+Sans:wght@400;500;600;700&display=swap');

.rubric-generator {
  min-height: 100vh;
  background: linear-gradient(135deg, #f8fafc, #f1f5f9);
  display: flex;
  flex-direction: column;
  font-family: 'Google Sans', sans-serif;

  .page-header {
    background: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(12px);
    border-bottom: 1px solid rgba(226, 232, 240, 0.8);
    padding: 1.25rem 1.5rem;
    position: sticky;
    top: 0;
    z-index: 50;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.02),
    0 2px 4px rgba(0, 0, 0, 0.01);

    .header-content {
      max-width: 1400px;
      margin: 0 auto;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .header-left {
      display: flex;
      align-items: center;
      gap: 2rem;
    }

    .back-button {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: #64748b;
      font-size: 0.875rem;
      font-weight: 500;
      text-decoration: none;
      padding: 0.5rem;
      border-radius: 0.375rem;
      transition: all 0.2s ease;

      &:hover {
        color: #334155;
        background: rgba(226, 232, 240, 0.4);
        transform: translateX(-2px);
      }

      &:active {
        transform: translateX(0);
      }
    }

    .gradewiz-logo-container {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    h1 {
      font-size: 1.75rem;
      font-weight: 600;
      background: linear-gradient(135deg, #1e293b 0%, #334155 100%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      letter-spacing: -0.02em;
    }

    .total-points {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      padding: 0.625rem 1.25rem;
      background: linear-gradient(135deg, #f9ede2 0%, #f5e5d5 100%);
      border-radius: 14px;
      color: #c9854a;
      font-weight: 600;
      font-size: 0.9375rem;
      box-shadow: 0 2px 4px rgba(228, 177, 122, 0.12),
      0 1px 2px rgba(228, 177, 122, 0.08);
      border: 1px solid rgba(228, 177, 122, 0.2);
      transition: all 0.2s ease;

      &:hover {
        transform: translateY(-1px);
        box-shadow: 0 4px 12px rgba(228, 177, 122, 0.15),
        0 2px 4px rgba(228, 177, 122, 0.1);
      }

      svg {
        color: #c9854a;
        filter: drop-shadow(0 1px 1px rgba(228, 177, 122, 0.3));
      }
    }
  }

  .split-layout {
    display: flex;
    flex: 1;
    max-width: 1400px;
    margin: 0 auto;
    width: 100%;
    gap: 1.5rem;
    padding: 1.25rem;
    height: calc(100vh - 73px);
    max-height: 91vh;

    .questions-panel {
      width: 380px;
      background: rgba(255, 255, 255, 0.95);
      border-radius: 20px;
      border: 1px solid rgba(226, 232, 240, 0.8);
      display: flex;
      flex-direction: column;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.03),
      0 2px 8px rgba(0, 0, 0, 0.02);
      backdrop-filter: blur(12px);

      .panel-header {
        padding: 1.25rem;
        display: flex;
        align-items: center;
        gap: 0.875rem;
        border-bottom: 1px solid rgba(226, 232, 240, 0.8);
        background: white;
        border-radius: 20px 20px 0 0;

        svg {
          color: #e4b17a;
          filter: drop-shadow(0 1px 1px rgba(228, 177, 122, 0.2));
        }

        h2 {
          font-size: 1.125rem;
          font-weight: 600;
          color: #1e293b;
          letter-spacing: -0.01em;
        }
      }

      .questions-list {
        flex: 1;
        overflow-y: auto;
        padding: 1.25rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-track {
          background: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background: #e2e8f0;
          border-radius: 100px;

          &:hover {
            background: #cbd5e1;
          }
        }

        .question-item {
          padding: 1.25rem;
          border: 1px solid rgba(226, 232, 240, 0.8);
          border-radius: 12px;
          cursor: pointer;
          transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
          background: white;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02),
          0 1px 2px rgba(0, 0, 0, 0.03);

          &:hover {
            transform: translateY(-1px);
            border-color: rgba(228, 177, 122, 0.2);
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.03),
            0 2px 4px rgba(0, 0, 0, 0.02);
          }

          &.active {
            border-color: #e4b17a;
            background: linear-gradient(to right bottom, rgba(253, 248, 243, 0.8), rgba(253, 248, 243, 0.4));
            box-shadow: 0 4px 16px rgba(228, 177, 122, 0.08),
            0 2px 4px rgba(228, 177, 122, 0.04);
          }

          .letter {
            font-weight: 600;
            color: #e4b17a;
            font-size: 0.875rem;
            min-width: 1.5rem;
          }

          .question-text {
            font-size: 0.875rem;
            color: #475569;
            line-height: 1.5;
          }
          
          .truncate-text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
          }

          p {
            font-size: 0.875rem;
            color: #475569;
            line-height: 1.5;
          }         
          }

            .question-header h3 {
              background: linear-gradient(135deg, #1e293b 0%, #334155 100%);
              -webkit-background-clip: text;
              background-clip: text;
              -webkit-text-fill-color: transparent;
            }

            .points-badge {
              background: white;
              border-color: rgba(228, 177, 122, 0.2);
              color: #e4b17a;
            }
          }
          

          .question-info {
            flex: 1;
            min-width: 0;

            .question-header {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 0.75rem;
              gap: 1rem;

              h3 {
                font-size: 1rem;
                font-weight: 600;
                color: #1e293b;
                transition: all 0.2s ease;
              }

              .points-badge {
                padding: 0.375rem 0.75rem;
                background: #f8fafc;
                border-radius: 12px;
                color: #e4b17a;
                font-weight: 500;
                font-size: 0.875rem;
                border: 1px solid rgba(226, 232, 240, 0.8);
                transition: all 0.2s ease;
              }
            }

            .preview-text {
              font-size: 0.9375rem;
              color: #64748b;
              line-height: 1.6;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }

          .chevron {
            color: #94a3b8;
            transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
            margin-top: 0.25rem;
            flex-shrink: 0;
          }
        }
      }

      .subquestions-list {
        margin-top: 1rem;
        padding-left: 1rem;
        border-left: 2px solid rgba(226, 232, 240, 0.8);
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .subquestion-item {
          padding: 0.75rem 1rem;
          display: flex;
          align-items: center;
          gap: 0.75rem;
          border-radius: 8px;
          border: 1px solid rgba(226, 232, 240, 0.8);
          transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
          background: rgba(255, 255, 255, 0.5);
          cursor: pointer;

          &:hover {
            background: rgba(253, 248, 243, 0.5);
          }

          &.active {
            background: #fdf8f3;
            border-color: #e4b17a;
            box-shadow: 0 2px 8px rgba(228, 177, 122, 0.08),
            0 1px 2px rgba(228, 177, 122, 0.04);
          }

          .letter {
            font-weight: 600;
            color: #e4b17a;
            font-size: 0.875rem;
            min-width: 1.5rem;
          }

          .text {
            font-size: 0.875rem;
            color: #475569;
            flex: 1;
          }

          .points-indicator {
            font-size: 0.75rem;
            font-weight: 600;
            color: #c9854a;
            background: linear-gradient(135deg, #f9ede2 0%, #f5e5d5 100%);
            padding: 0.25rem 0.5rem;
            border-radius: 6px;
            border: 1px solid rgba(228, 177, 122, 0.2);
            box-shadow: 0 1px 2px rgba(228, 177, 122, 0.08);
          }

        .subquestion-info {
          flex: 1;
          min-width: 0;

          .subquestion-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 0.5rem;
            gap: 1rem;

            h4 {
              font-size: 0.875rem;
              font-weight: 600;
              color: #334155;
              transition: all 0.2s ease;
            }

            .points-badge {
              background: white;
              border-color: rgba(228, 177, 122, 0.2);
              color: #e4b17a;
            }
          }

          .preview-text {
            font-size: 0.875rem;
            color: #64748b;
            line-height: 1.5;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }

    }

    .rubric-panel {
      flex: 1;
      background: rgba(255, 255, 255, 0.95);
      border-radius: 20px;
      border: 1px solid rgba(226, 232, 240, 0.8);
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.03),
      0 2px 8px rgba(0, 0, 0, 0.02);
      overflow-y: auto;
      backdrop-filter: blur(12px);

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: #e2e8f0;
        border-radius: 100px;

        &:hover {
          background: #cbd5e1;
        }
      }

      .empty-state {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2rem;
        color: #94a3b8;
        text-align: center;
        background-color: #fff;

        svg {
          color: #e2e8f0;
          margin-bottom: 1rem;
          filter: drop-shadow(0 1px 2px rgba(148, 163, 184, 0.1));
        }

        h3 {
          font-size: 1.25rem;
          font-weight: 600;
          color: #64748b;
          margin-bottom: 0.75rem;
          letter-spacing: -0.01em;
        }

        p {
          font-size: 0.9375rem;
          color: #94a3b8;
          max-width: 320px;
          line-height: 1.6;
        }
      }

      .rubric-content {
        padding: 1.5rem;

        .rubric-header {
          margin-bottom: 2rem;

          .title-section {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 1.25rem;

            h2 {
              font-size: 1.25rem;
              font-weight: 600;
              background: linear-gradient(135deg, #1e293b 0%, #334155 100%);
              -webkit-background-clip: text;
              background-clip: text;
              -webkit-text-fill-color: transparent;
              letter-spacing: -0.02em;
            }


            .points-badge {
              display: flex;
              align-items: center;
              gap: 0.625rem;
              padding: 0.625rem 1rem;
              background: linear-gradient(135deg, #f9ede2 0%, #f5e5d5 100%);
              border-radius: 12px;
              border-color: rgba(228, 177, 122, 0.2);
              color: #c9854a;
              font-weight: 600;
              font-size: 0.9375rem;
              box-shadow: 0 2px 4px rgba(228, 177, 122, 0.12),
              0 1px 2px rgba(228, 177, 122, 0.08);
              border: 1px solid rgba(228, 177, 122, 0.2);
              transition: all 0.2s ease;

              &:hover {
                transform: translateY(-1px);
                box-shadow: 0 4px 12px rgba(228, 177, 122, 0.15),
                0 2px 4px rgba(228, 177, 122, 0.1);
              }

              svg {
                filter: drop-shadow(0 1px 1px rgba(228, 177, 122, 0.3));
              }
            }
          }

          .question-text {
            color: #475569;
            line-height: 1.7;
            font-size: 1rem;
            background: #f8fafc;
            padding: 1.25rem;
            border-radius: 12px;
            border: 1px solid rgba(226, 232, 240, 0.8);
          }
        }

        .grading-criteria {
          h3 {
            font-size: 1.25rem;
            font-weight: 600;
            color: #e4b17a;
            margin-bottom: 1.25rem;
            padding-bottom: 0.75rem;
            border-bottom: 1px solid rgba(228, 177, 122, 0.8);
            letter-spacing: -0.01em;
          }

          .criteria-list {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .criteria-item {
              display: flex;
              gap: 1.25rem;
              padding: 1.25rem;
              background: white;
              border-radius: 16px;
              transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
              border: 1px solid rgba(228, 177, 122, 0.8);
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02),
              0 1px 2px rgba(0, 0, 0, 0.01);
              position: relative;

              &:hover {
                border-color: #e4b17a;
                transform: translateY(-1px);
                box-shadow: 0 4px 12px rgba(228, 177, 122, 0.08),
                0 2px 4px rgba(228, 177, 122, 0.04);
              }
              
              .delete-criteria-btn {
                position: absolute;
                top: 10px;
                right: 10px;
                background: transparent;
                border: none;
                color: #94a3b8;
                cursor: pointer;
                padding: 5px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.2s ease;
                opacity: 0.6;
                
                &:hover {
                  color: #ef4444;
                  background: rgba(239, 68, 68, 0.1);
                  opacity: 1;
                }
                
                &:disabled {
                  opacity: 0.3;
                  cursor: not-allowed;
                  
                  &:hover {
                    color: #94a3b8;
                    background: transparent;
                  }
                }
              }

              .points {
                min-width: 80px;
                background: #f8fafc;
                padding: 0.75rem;
                border-radius: 12px;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 0.25rem;
                //border: 1px solid rgba(228, 177, 122, 0.8);
                transition: all 0.2s ease;

                .value {
                  font-size: 1.25rem;
                  font-weight: 600;
                  color: #e4b17a;
                }

                .label {
                  font-size: 0.875rem;
                  color: #64748b;
                }
              }

              .description {
                flex: 1;

                textarea {
                  width: 100%;
                  background: transparent;
                  border: none;
                  color: #475569;
                  font-size: 0.9375rem;
                  line-height: 1.6;
                  padding: 0.75rem;
                  margin: 0;
                  outline: none;
                  resize: none;
                  font-family: 'Google Sans', sans-serif;
                  min-height: 24px;
                  border-radius: 12px;
                  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);

                  &::placeholder {
                    color: #94a3b8;
                  }

                  &:hover {
                    background: #f8fafc;
                  }

                  &:focus {
                    background: #f8fafc;
                    box-shadow: 0 0 0 2px rgba(228, 177, 122, 0.2),
                    0 2px 4px rgba(0, 0, 0, 0.02);
                  }
                }

                p {
                  color: #475569;
                  font-size: 0.9375rem;
                  line-height: 1.6;
                  padding: 0.75rem;
                }
              }
            }
          }
          
          .add-criteria-btn {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            margin-top: 1rem;
            padding: 0.75rem 1.25rem;
            background: linear-gradient(135deg, #fdf8f3 0%, #f9ede2 100%);
            color: #e4b17a;
            border: 1px dashed rgba(228, 177, 122, 0.4);
            border-radius: 12px;
            font-size: 0.9375rem;
            font-weight: 500;
            cursor: pointer;
            transition: all 0.2s ease;
            width: 100%;
            justify-content: center;
            
            &:hover {
              background: linear-gradient(135deg, #f9ede2 0%, #f5e5d5 100%);
              border-color: rgba(228, 177, 122, 0.6);
              transform: translateY(-1px);
              box-shadow: 0 4px 12px rgba(228, 177, 122, 0.08),
              0 2px 4px rgba(228, 177, 122, 0.04);
            }
            
            svg {
              color: #e4b17a;
            }
          }
        }
      }
    }
  }

  .actions-footer {
    background: rgba(255, 255, 255, 0.9);
    border-top: 1px solid rgba(226, 232, 240, 0.8);
    padding: 1rem 1.5rem;
    position: sticky;
    bottom: 0;
    z-index: 50;
    backdrop-filter: blur(10px);
    box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.02),
    0 -2px 4px rgba(0, 0, 0, 0.01);

    .generate-btn {
      display: inline-flex;
      align-items: center;
      gap: 0.875rem;
      padding: 0.875rem 1.5rem;
      background: linear-gradient(135deg, #e4b17a 0%, #c99b69 100%);
      color: white;
      border: none;
      border-radius: 14px;
      font-size: 1rem;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      box-shadow: 0 2px 4px rgba(228, 177, 122, 0.15),
      0 1px 2px rgba(228, 177, 122, 0.1);
      font-family: 'Google Sans', sans-serif;
      letter-spacing: -0.01em;

      svg {
        filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.1));
      }

      &:hover {
        background: linear-gradient(135deg, #c99b69 0%, #b38a5e 100%);
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba(228, 177, 122, 0.2),
        0 2px 4px rgba(228, 177, 122, 0.15);
      }

      &:active {
        transform: translateY(0);
        box-shadow: 0 2px 4px rgba(228, 177, 122, 0.15),
        0 1px 2px rgba(228, 177, 122, 0.1);
      }
    }
  }

  .edit-toggle {
    display: inline-flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.625rem 1.25rem;
    background: white;
    border: 1px solid rgba(226, 232, 240, 0.8);
    border-radius: 14px;
    color: #64748b;
    font-weight: 500;
    font-size: 0.9375rem;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    font-family: 'Google Sans', sans-serif;
  }


.header-actions {
  display: flex;
  align-items: center;
  gap: 1rem;

  .edit-toggle {
    display: inline-flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.625rem 1.25rem;
    background: white;
    border: 1px solid rgba(226, 232, 240, 0.8);
    border-radius: 14px;
    color: #64748b;
    font-weight: 500;
    font-size: 0.9375rem;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    font-family: 'Google Sans', sans-serif;

    svg {
      transition: all 0.2s ease;
    }

    &:hover {
      background: #f8fafc;
      border-color: #e2e8f0;
      color: #475569;

      svg {
        color: #e4b17a;
      }
    }

    &.active {
      background: #fdf8f3;
      border-color: #e4b17a;
      color: #e4b17a;

      &:hover {
        background: #f9ede2;
      }
    }
  }

  .generate-pdf-btn {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.25rem;
    background: linear-gradient(40deg, #19a75b, hsl(142.1, 76.2%, 36.3%));
    color: white;
    border: none;
    border-radius: 60px;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    // transition: all 0.2s ease;
    transition: 0.3s;

    &:hover {
      transform: translateY(-2px);
      transition: 0.5s;
      background: linear-gradient(40deg, #218d53, hsl(142.1, 76.2%, 36.3%));
      box-shadow: 0px 0px 10px #ccc;

      svg {
        transform: scale(1.2);
        transition: 0.3s;

      }
    }
  }
}

.points-control {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: #f8fafc;
  padding: 0.25rem;
  border-radius: 10px;
  border: 1px solid rgba(226, 232, 240, 0.8);
  transition: all 0.2s ease;

  &:hover {
    border-color: rgba(228, 177, 122, 0.2);
  }

  .points-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    padding: 0;
    border: none;
    background: white;
    border-radius: 6px;
    color: #64748b;
    cursor: pointer;
    transition: all 0.2s ease;
    border: 1px solid rgba(226, 232, 240, 0.8);

    &:hover {
      background: #fdf8f3;
      color: #e4b17a;
      border-color: rgba(228, 177, 122, 0.2);
    }

    &:active {
      background: #dbeafe;
      transform: scale(0.95);
    }

    svg {
      width: 14px;
      height: 14px;
      stroke-width: 2.5;
    }
  }

  .points-value {
    min-width: 20px;
    text-align: center;
    font-weight: 600;
    color: #e4b17a;
    font-size: 0.9375rem;
  }
}

.points-display {
  display: inline-flex;
  padding: 0.25rem 0.625rem;
  background: #f8fafc;
  border-radius: 10px;
  color: #3b82f6;
  font-weight: 500;
  font-size: 0.875rem;
  border: 1px solid rgba(226, 232, 240, 0.8);
}

.loading-overlay {
  position: fixed;
  inset: 0;
  background: linear-gradient(135deg, #f8fafc 0%, #f1f5f9 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  .loading-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 400px;
    padding: 2rem;
    animation: fadeIn 0.5s ease-out;

    .loading-icon {
      color: #e4b17a;
      margin-bottom: 1.5rem;
      animation: sparkle 2s ease-in-out infinite;
      filter: drop-shadow(0 4px 12px rgba(228, 177, 122, 0.2));
    }

    h2 {
      font-size: 1.5rem;
      font-weight: 600;
      color: #1e293b;
      margin-bottom: 0.75rem;
      background: linear-gradient(135deg, #1e293b 0%, #334155 100%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    p {
      color: #64748b;
      font-size: 1rem;
      line-height: 1.6;
      margin-bottom: 2rem;
    }

    .loading-bar {
      width: 100%;
      height: 4px;
      background: #e2e8f0;
      border-radius: 100px;
      overflow: hidden;
      position: relative;

      .loading-progress {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        background: linear-gradient(
                        90deg,
                        #e4b17a 0%,
                        #c99b69 50%,
                        #e4b17a 100%
        );
        width: 30%;
        border-radius: 100px;
        animation: loading 2s ease-in-out infinite;
        background-size: 200% 100%;
      }
    }
  }
}

.error-state {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2rem;
  animation: fadeIn 0.5s ease-out;

  svg {
    color: #e2e8f0;
    margin-bottom: 1.5rem;
    animation: float 3s ease-in-out infinite;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #1e293b;
    margin-bottom: 0.75rem;
    background: linear-gradient(135deg, #1e293b 0%, #334155 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  p {
    color: #64748b;
    font-size: 1rem;
    line-height: 1.6;
    max-width: 320px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes sparkle {
  0% {
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }
  50% {
    transform: scale(1.1) rotate(180deg);
    opacity: 0.8;
  }
  100% {
    transform: scale(1) rotate(360deg);
    opacity: 1;
  }
}

@keyframes loading {
  0% {
    left: -30%;
    background-position: 0% 50%;
  }
  50% {
    left: 100%;
    background-position: 100% 50%;
  }
  100% {
    left: -30%;
    background-position: 0% 50%;
  }
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}


.solution-section {
  margin-top: 2.5rem;
  background: #ffffff;
  border-radius: 1.5rem;
  border: 1px solid rgba(228, 177, 122, 0.12);
  box-shadow: 0 2px 4px -2px rgba(228, 177, 122, 0.06),
  0 4px 8px -2px rgba(228, 177, 122, 0.04);
  position: relative;
  isolation: isolate;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
                    135deg,
                    rgba(253, 248, 243, 0.5) 0%,
                    rgba(255, 255, 255, 0.5) 50%,
                    rgba(253, 248, 243, 0.5) 100%
    );
    opacity: 0;
    transition: opacity 0.4s ease;
    z-index: -1;
  }

  &:hover {
    transform: translateY(-2px);
    border-color: rgba(228, 177, 122, 0.2);
    box-shadow: 0 12px 24px -6px rgba(228, 177, 122, 0.08),
    0 18px 36px -12px rgba(228, 177, 122, 0.06);

    &::before {
      opacity: 1;
    }
  }

  .solution-header {
    padding: 1.75rem 2.5rem;
    background: linear-gradient(
                    to right,
                    rgba(253, 248, 243, 0.8),
                    rgba(255, 255, 255, 0.8)
    );
    backdrop-filter: blur(8px);
    border-bottom: 1px solid rgba(228, 177, 122, 0.08);
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 1px;
      background: linear-gradient(
                      to right,
                      transparent,
                      rgba(228, 177, 122, 0.2),
                      transparent
      );
    }

    h3 {
      display: flex;
      align-items: center;
      gap: 0.875rem;
      font-size: 1.375rem;
      font-weight: 600;
      color: #8c6b4a;
      margin-bottom: 0.625rem;
      letter-spacing: -0.02em;

      svg {
        color: #e4b17a;
        filter: drop-shadow(0 2px 4px rgba(228, 177, 122, 0.2));
        transition: transform 0.3s ease;
      }
    }

    .solution-description {
      font-size: 0.9375rem;
      color: #a17c57;
      letter-spacing: -0.01em;
      line-height: 1.5;
      opacity: 0.9;
    }
  }

  .solution-content {
    padding: 2.5rem;

    .solution-textarea {
      width: 100%;
      min-height: 240px;
      padding: 1.5rem;
      background: #fdf8f3;
      border: 1px solid rgba(228, 177, 122, 0.15);
      border-radius: 1rem;
      font-family: 'JetBrains Mono', 'Fira Code', 'Monaco', monospace;
      font-size: 0.9375rem;
      line-height: 1.7;
      color: #1a1a1a;
      resize: vertical;
      transition: all 0.25s ease;

      &:focus {
        outline: none;
        border-color: #e4b17a;
        box-shadow: 0 0 0 3px rgba(228, 177, 122, 0.1),
        0 4px 12px -2px rgba(228, 177, 122, 0.08);
      }

      &::placeholder {
        color: #c99b69;
        opacity: 0.6;
      }
    }

    .solution-text {
      color: #1a1a1a;
      line-height: 1.85;
      font-size: 1.0625rem;
      letter-spacing: -0.01em;

      .solution-paragraph {
        margin-bottom: 2.75rem;
        padding: 0.25rem 0;
        opacity: 0.95;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .math-block {
        margin: 2rem 0;
        padding: 2rem;
        background: linear-gradient(
                        135deg,
                        rgba(253, 248, 243, 0.8) 0%,
                        rgba(255, 255, 255, 0.8) 100%
        );
        border-radius: 1rem;
        border: 1px solid rgba(228, 177, 122, 0.12);
        overflow-x: auto;
        position: relative;
        transition: all 0.3s ease;

        &::before {
          content: '';
          position: absolute;
          inset: 0;
          border-radius: inherit;
          padding: 1px;
          background: linear-gradient(
                          135deg,
                          rgba(228, 177, 122, 0.2),
                          rgba(228, 177, 122, 0.1)
          );
          mask: linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
          -webkit-mask-composite: xor;
          mask-composite: exclude;
          pointer-events: none;
        }

        &:hover {
          transform: scale(1.01);
          border-color: rgba(228, 177, 122, 0.2);
          box-shadow: 0 8px 16px -4px rgba(228, 177, 122, 0.06),
          0 2px 4px -1px rgba(228, 177, 122, 0.04);
        }

        .katex-display {
          margin: 0;
          opacity: 0.95;
        }

        &::-webkit-scrollbar {
          height: 6px;
        }

        &::-webkit-scrollbar-track {
          background: rgba(228, 177, 122, 0.05);
          border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
          background: rgba(228, 177, 122, 0.2);
          border-radius: 3px;

          &:hover {
            background: rgba(228, 177, 122, 0.3);
          }
        }
      }

      // Style inline math
      .katex {
        font-size: 1.1em;
        color: #8c6b4a;
      }

      // Add special styling for math elements
      .katex-html {
        .mord, .mbin, .mrel, .mopen, .mclose, .mpunct {
          color: #8c6b4a;
        }

        .mop, .minner {
          color: #c99b69;
        }

        .mfrac {
          .frac-line {
            border-color: #e4b17a;
          }
        }

        .sqrt {
          .sqrt-sign {
            color: #e4b17a;
          }
        }
      }
    }
  }

  // Add a subtle animation when the section appears
  @keyframes solutionFadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  animation: solutionFadeIn 0.5s ease-out forwards;
}
