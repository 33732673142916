.options-list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: 1.25rem;
  width: 100%;
  
  .option-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 0.75rem;
  }
  
  .option-actions {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  .option-item {
    display: flex;
    gap: 1rem;
    padding: 1rem;
    background: hsl(210 40% 98%);
    border: 1px solid hsl(214.3 31.8% 91.4%);
    border-radius: 0.75rem;
    transition: all 0.3s ease;
    width: 100%;
    min-height: 3.5rem;

    &:hover {
      background: white;
      transform: translateY(-1px);
      box-shadow: 0 2px 4px hsla(0, 0%, 0%, 0.03),
                 0 4px 8px hsla(0, 0%, 0%, 0.03);
    }

    &.correct {
      background: hsla(142.1, 76.2%, 36.3%, 0.05);
      border-color: hsla(142.1, 76.2%, 36.3%, 0.2);

      .option-letter {
        background: hsl(142.1, 76.2%, 36.3%);
        color: white;
        border-color: hsl(142.1, 76.2%, 36.3%);
      }

      &:hover {
        background: hsla(142.1, 76.2%, 36.3%, 0.08);
      }
    }

    .option-letter {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2rem;
      height: 2rem;
      background: white;
      border: 1px solid hsl(214.3, 31.8%, 91.4%);
      border-radius: 0.5rem;
      color: hsl(215.4, 16.3%, 46.9%);
      font-weight: 600;
      font-size: 0.875rem;
      flex-shrink: 0;
    }

    .option-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      width: calc(100% - 3rem); /* Account for the option letter width and padding */

      .option-text {
        color: hsl(222.2, 47.4%, 11.2%);
        font-size: 1rem;
        line-height: 1.6;
        width: 100%;
        min-height: 2.5rem;

        .katex {
          font-size: 1.1em;
          margin: 0 0.1em;
        }
      }

      .correct-indicator {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        color: hsl(142.1, 76.2%, 36.3%);
        font-size: 0.8125rem;
        font-weight: 500;

        .correct-mark {
          width: 1rem;
          height: 1rem;
          stroke-width: 2.5;
        }
      }
    }
  }
}

/* MCQ Options Editor Styles */
.mcq-options-editor {
  margin: 1.5rem 0;
  padding: 1.25rem;
  border: 1px solid hsl(214.3, 31.8%, 91.4%);
  border-radius: 0.75rem;
  background-color: hsl(210, 40%, 98%);

  .editor-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.25rem;

    h4 {
      margin: 0;
      font-size: 1rem;
      font-weight: 600;
      color: hsl(222.2, 47.4%, 11.2%);
    }

    .multiple-correct-toggle {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-size: 0.875rem;
      color: hsl(215.4, 16.3%, 46.9%);
      cursor: pointer;

      input[type="checkbox"] {
        cursor: pointer;
      }

      &:hover {
        color: hsl(222.2, 47.4%, 11.2%);
      }
    }
  }

  .mcq-option-editor {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 0.75rem;
    padding: 0.75rem;
    border-radius: 0.5rem;
    background-color: white;
    border: 1px solid hsl(214.3, 31.8%, 91.4%);
    transition: all 0.2s ease;

    &:hover {
      box-shadow: 0 2px 4px hsla(0, 0%, 0%, 0.03),
                 0 4px 8px hsla(0, 0%, 0%, 0.03);
    }

    .option-letter {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2rem;
      height: 2rem;
      background: hsl(210, 40%, 98%);
      border: 1px solid hsl(214.3, 31.8%, 91.4%);
      border-radius: 0.5rem;
      color: hsl(215.4, 16.3%, 46.9%);
      font-weight: 600;
      font-size: 0.875rem;
      flex-shrink: 0;
    }

    .option-text-input {
      flex-grow: 1;
      padding: 0.5rem 0.75rem;
      border: 1px solid hsl(214.3, 31.8%, 91.4%);
      border-radius: 0.375rem;
      font-size: 0.9375rem;
      color: hsl(222.2, 47.4%, 11.2%);
      transition: border-color 0.2s ease;

      &:focus {
        outline: none;
        border-color: hsl(221.2, 83.2%, 53.3%);
        box-shadow: 0 0 0 2px hsla(221.2, 83.2%, 53.3%, 0.1);
      }
    }

    .option-controls {
      display: flex;
      align-items: center;
      gap: 0.75rem;

      .correct-option-label {
        display: flex;
        align-items: center;
        gap: 0.375rem;
        font-size: 0.875rem;
        color: hsl(215.4, 16.3%, 46.9%);
        cursor: pointer;

        input[type="checkbox"] {
          cursor: pointer;
          width: 1rem;
          height: 1rem;
        }

        &:hover {
          color: hsl(222.2, 47.4%, 11.2%);
        }
      }

      .delete-option-btn {
        background: none;
        border: none;
        color: hsl(0, 84.2%, 60.2%);
        cursor: pointer;
        padding: 0.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.25rem;

        &:hover {
          background-color: hsla(0, 84.2%, 60.2%, 0.1);
        }
      }
    }
  }

  .add-option-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    background-color: white;
    color: hsl(221.2, 83.2%, 53.3%);
    border: 1px solid hsl(221.2, 83.2%, 53.3%);
    border-radius: 0.5rem;
    padding: 0.75rem 1rem;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    width: 100%;
    transition: all 0.2s ease;
    margin: 1rem 0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

    &:hover {
      background-color: hsla(221.2, 83.2%, 53.3%, 0.1);
      transform: translateY(-1px);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  }

  .editor-actions {
    display: flex;
    justify-content: flex-end;
    gap: 0.75rem;
    margin-top: 1rem;

    .save-btn {
      display: flex;
      align-items: center;
      gap: 0.375rem;
      background-color: hsl(142.1, 76.2%, 36.3%);
      color: white;
      border: none;
      border-radius: 0.375rem;
      padding: 0.5rem 1rem;
      font-size: 0.875rem;
      font-weight: 500;
      cursor: pointer;
      transition: background-color 0.2s ease;

      &:hover {
        background-color: hsl(142.1, 76.2%, 33%);
      }
    }
  }
}

.edit-options-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: none;
  border: 1px solid hsl(214.3, 31.8%, 91.4%);
  color: hsl(215.4, 16.3%, 46.9%);
  border-radius: 0.375rem;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  margin-top: 0.75rem;
  cursor: pointer;
  transition: all 0.2s ease;
  width: fit-content;
  align-self: flex-end;

  &:hover {
    background-color: hsl(210, 40%, 98%);
    color: hsl(222.2, 47.4%, 11.2%);
    border-color: hsl(221.2, 83.2%, 53.3%);
  }

  svg {
    stroke-width: 2;
  }
}
