.pdf-viewer {
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #f1f5f9;
  position: relative;

  .pdf-content {
    position: relative;
    width: fit-content;
    margin: 2rem auto;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  }

  .pdf-image {
    display: block;
    max-width: none;
    height: auto;
  }

  .viewer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }

  .viewer-tabs {
    display: flex;
    gap: 1rem;
  }

  .pdf-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: all;

    &.draw-mode {
      cursor: crosshair;
    }
  }

  .drawing-preview {
    position: absolute;
    border: 2px solid #e4b17a;
    background: rgba(228, 177, 122, 0.1);
  }

  .saved-box {
    position: absolute;
    border: 2px solid #e4b17a;
    background: rgba(228, 177, 122, 0.1);
    cursor: pointer;
    transition: all 0.2s;

    .box-label {
      position: absolute;
      top: -1.5rem;
      left: -2px;
      background: #e4b17a;
      color: white;
      font-size: 0.75rem;
      font-weight: 500;
      padding: 0.125rem 0.375rem;
      border-radius: 0.25rem;
      white-space: nowrap;
      // Removed opacity and transform transitions
    }

    &:hover {
      background: rgba(228, 177, 122, 0.2);
    }

    &.selected {
      border-color: #d39a63;
      background: rgba(211, 154, 99, 0.2);
      box-shadow: 0 0 0 2px rgba(211, 154, 99, 0.4);

      .box-label {
        background: #d39a63;
      }
    }

    .delete-button {
      position: absolute;
      top: -1.5rem;
      right: -2px;
      background: #ef4444;
      color: white;
      font-size: 1rem;
      font-weight: 500;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 0.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1000; /* Ensure it's above other elements */

      &::after {
        content: '';
        position: absolute;
        top: -8px;
        right: -8px;
        bottom: -8px;
        left: -8px;
        border-radius: 50%;
      }

      &:hover {
        background: #dc2626;
      }
    }
  }
}