.template-editor {
  .split-container {
    display: flex;
    height: calc(100vh - 73px);
    width: 100%;
    background: white;
    overflow: hidden;
  }

  .viewer-panel {
    flex: 1;
    height: 100%;
    background: #f1f5f9;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .empty-viewer {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    color: #94a3b8;
    padding: 2rem;

    svg {
      width: 48px;
      height: 48px;
      color: #cbd5e1;
    }

    h2 {
      font-size: 1.25rem;
      font-weight: 500;
      color: #64748b;
      letter-spacing: -0.02em;
    }

    p {
      font-size: 0.875rem;
      color: #64748b;
      text-align: center;
      max-width: 320px;
      line-height: 1.5;
    }
  }

  .editor-content {
    flex: 1;
    display: flex;
    min-height: 0;

    .questions-panel {
      width: 360px;
      background: white;
      border-right: 1px solid rgba(226, 232, 240, 0.8);
      display: flex;
      flex-direction: column;
      box-shadow: 4px 0 16px -12px rgba(0, 0, 0, 0.1);

      .panel-header {
        padding: 1.25rem;
        display: flex;
        align-items: center;
        gap: 0.75rem;
        background: rgba(255, 255, 255, 0.9);
        backdrop-filter: blur(8px);
        border-bottom: 1px solid rgba(226, 232, 240, 0.8);
        position: sticky;
        top: 0;
        z-index: 10;

        svg {
          color: #3b82f6;
        }

        h2 {
          font-size: 1rem;
          font-weight: 600;
          color: #1e293b;
          letter-spacing: -0.01em;
        }
      }

      .questions-list {
        flex: 1;
        overflow-y: auto;
        padding: 1.25rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-track {
          background: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background: #e2e8f0;
          border-radius: 100px;

          &:hover {
            background: #cbd5e1;
          }
        }

        .question-item {
          padding: 1.25rem;
          border: 1px solid rgba(226, 232, 240, 0.8);
          border-radius: 12px;
          cursor: pointer;
          transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
          background: white;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02),
          0 1px 2px rgba(0, 0, 0, 0.03);

          &:hover {
            transform: translateY(-1px);
            border-color: rgba(228, 177, 122, 0.2);
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.03),
            0 2px 4px rgba(0, 0, 0, 0.02);
          }

          &.active {
            border-color: #e4b17a;
            background: linear-gradient(to right bottom, rgba(253, 248, 243, 0.8), rgba(253, 248, 243, 0.4));
            box-shadow: 0 4px 16px rgba(228, 177, 122, 0.08),
            0 2px 4px rgba(228, 177, 122, 0.04);
          }

          .question-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0.75rem;

            h3 {
              font-size: 0.9375rem;
              font-weight: 600;
              color: #1e293b;
              letter-spacing: -0.01em;
            }

            svg {
              color: #e4b17a;
              filter: drop-shadow(0 1px 2px rgba(228, 177, 122, 0.2));
              transition: all 0.2s ease;
            }
          }

          p {
            font-size: 0.875rem;
            color: #475569;
            line-height: 1.5;
          }

          .truncate-text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
          }

          .subquestions-list {
            margin-top: 1rem;
            padding-left: 1rem;
            border-left: 2px solid rgba(226, 232, 240, 0.8);
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            .subquestion-item {
              padding: 0.75rem 1rem;
              display: flex;
              align-items: center;
              gap: 0.75rem;
              border-radius: 8px;
              transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
              background: rgba(255, 255, 255, 0.5);

              &:hover {
                background: rgba(253, 248, 243, 0.5);
              }

              &.active {
                background: #fdf8f3;
                box-shadow: 0 2px 8px rgba(228, 177, 122, 0.08),
                0 1px 2px rgba(228, 177, 122, 0.04);
              }

              .letter {
                font-weight: 600;
                color: #e4b17a;
                font-size: 0.875rem;
                min-width: 1.5rem;
              }

              .text {
                font-size: 0.875rem;
                color: #475569;
                flex: 1;
                line-height: 1.5;
              }

              .truncate-text {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 180px;
              }

              svg {
                color: #e4b17a;
                opacity: 0.9;
                filter: drop-shadow(0 1px 1px rgba(228, 177, 122, 0.1));
              }
            }
          }
        }
      }
    }

    .pdf-workspace {
      flex: 1;
      background: #f8fafc;
      overflow: hidden;
      display: flex;

      .upload-prompt {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2rem;

        .upload-label {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          cursor: pointer;
          padding: 2rem;
          background: white;
          border: 2px dashed #e2e8f0;
          border-radius: 1rem;
          transition: all 0.2s;

          &:hover {
            border-color: #3b82f6;
            background: #f0f9ff;
          }

          h3 {
            margin: 1rem 0 0.5rem;
            font-size: 1.125rem;
            font-weight: 600;
            color: #1e293b;
          }

          p {
            color: #64748b;
            font-size: 0.875rem;
          }
        }
      }
    }
  }

  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    border-bottom: 1px solid #e2e8f0;
    background: white;

    .header-left {
      display: flex;
      align-items: center;
      gap: 2rem;
    }

    .back-button {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: #64748b;
      font-size: 0.875rem;
      font-weight: 500;
      text-decoration: none;
      padding: 0.5rem;
      border-radius: 0.375rem;
      transition: all 0.2s ease;

      &:hover {
        color: #334155;
        background: rgba(226, 232, 240, 0.4);
        transform: translateX(-2px);
      }

      &:active {
        transform: translateX(0);
      }
    }

    .gradewiz-logo-container {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    .header-actions {
      display: flex;
      gap: 1rem;
      align-items: center;
      position: relative; // Add this to contain the tooltip
      
      .proceed-btn {
        display: inline-flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.75rem 1.25rem;
        background: linear-gradient(135deg, #e4b17a, #c99b69);
        color: white;
        border: none;
        border-radius: 0.5rem;
        font-size: 0.875rem;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.2s ease;
        box-shadow: 0 2px 4px rgba(228, 177, 122, 0.2);
        position: relative;
        overflow: hidden;

        &:hover {
          background: linear-gradient(135deg, #c99b69, #b38a5e);
          transform: translateY(-1px);
          box-shadow: 0 4px 8px rgba(228, 177, 122, 0.3);
        }

        &:active {
          transform: translateY(0);
          box-shadow: 0 2px 4px rgba(228, 177, 122, 0.2);
        }
        
        &.loading {
          background: linear-gradient(135deg, #c99b69, #b38a5e);
          cursor: not-allowed;
          
          .spinner {
            display: inline-block;
            width: 20px;
            height: 20px;
            border: 2px solid rgba(255, 255, 255, 0.3);
            border-radius: 50%;
            border-top-color: white;
            animation: spin 1s linear infinite;
          }
          
          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(90deg, 
              rgba(255, 255, 255, 0) 25%, 
              rgba(255, 255, 255, 0.15) 50%, 
              rgba(255, 255, 255, 0) 75%);
            z-index: 1;
            animation: shimmer 2s infinite;
            transform: translateX(-100%);
          }
        }
        
        &.disabled {
          opacity: 0.6;
          cursor: not-allowed;
          background: #e0e0e0;
        }

        &[data-tooltip] {
          &:hover + .tooltip,
          &:focus + .tooltip {
            opacity: 1;
            transform: translateY(0);
            pointer-events: auto;
          }
        }
      }

      .tooltip {
        margin-top: 2.5rem;
        background: rgb(255, 255, 255);
        border-radius: 1.5rem;
        border: 1px solid rgba(228, 177, 122, 0.12);
        box-shadow: rgba(228, 177, 122, 0.06) 0px 2px 4px -2px,
                    rgba(228, 177, 122, 0.04) 0px 4px 8px -2px;
        position: absolute;
        bottom: calc(100% + 10px);
        left: 50%;
        transform: translateX(-50%) translateY(10px);
        padding: 1.5rem;
        font-size: 14px;
        white-space: pre-wrap;
        max-width: 400px;
        min-width: 300px;
        z-index: 1000;
        isolation: isolate;
        opacity: 0;
        pointer-events: none;
        transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
        animation: tooltipFadeIn 0.5s ease-out forwards;
        color: #E4B17A;
        font-family: "Google Sans", system-ui, -apple-system, sans-serif;

        &::before {
          opacity: 1;
          content: "";
          position: absolute;
          inset: 0;
          background: linear-gradient(135deg, 
            rgba(253, 248, 243, 0.5) 0%, 
            rgba(255, 255, 255, 0.5) 50%, 
            rgba(253, 248, 243, 0.5) 100%);
          transition: opacity 0.4s;
          z-index: -1;
          border-radius: inherit;
        }

        &:hover {
          transform: translateX(-50%) translateY(-2px);
          border-color: rgba(228, 177, 122, 0.2);
          box-shadow: rgba(228, 177, 122, 0.08) 0px 12px 24px -6px,
                      rgba(228, 177, 122, 0.06) 0px 18px 36px -12px;
        }
      }
    }

    .preview-pdf-btn {
      display: inline-flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.75rem 1.25rem;
      background: white;
      color: #e4b17a;
      border: 1px solid #e4b17a;
      border-radius: 0.5rem;
      font-size: 0.875rem;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        background: #fdf8f3;
        transform: translateY(-1px);
      }

      &:active {
        transform: translateY(0);
      }
    }



    .generate-pdf-btn {
      display: inline-flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.75rem 1.25rem;
      background: linear-gradient(135deg, #e4b17a, #c99b69);
      color: white;

      border: none;
      border-radius: 60px;
      font-size: 0.875rem;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        background: linear-gradient(135deg, #c99b69, #b38a5e);
        transform: translateY(-1px);
      }

      &:active {
        transform: translateY(0);
      }
    }
  }

  .drawing-instruction {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1rem 1.5rem;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    color: #64748b;
    font-size: 0.875rem;
    pointer-events: none;
    z-index: 10;
  }

  .cursor-crosshair {
    cursor: none !important;

    &::after {
      content: '';
      position: fixed;
      width: 20px;
      height: 20px;
      border: 2px solid #e4b17a;
      border-radius: 50%;
      pointer-events: none;
      transform: translate(-50%, -50%);
      z-index: 1000;
      animation: cursorPulse 1.5s ease-in-out infinite;
    }
  }

  @keyframes cursorPulse {
    0% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
    50% {
      transform: translate(-50%, -50%) scale(1.2);
      opacity: 0.7;
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
  }

  .cursor-not-allowed {
    cursor: not-allowed;
  }
}

.selected-question-indicator {
  position: fixed;
  bottom: 2rem;
  right: 0.5rem;
  // left: 50%;
  // transform: translateX(-50%);
  background: rgba(17, 24, 39, 0.95);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 0.875rem 1.5rem;
  border-radius: 16px;
  color: white;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 1.25rem;
  box-shadow: 0 4px 24px -6px rgba(0, 0, 0, 0.3),
  0 12px 40px -8px rgba(0, 0, 0, 0.25),
  inset 0 1px 1px rgba(255, 255, 255, 0.1),
  inset 0 -1px 1px rgba(0, 0, 0, 0.1);
  z-index: 100;
  animation: slideUp 0.4s cubic-bezier(0.16, 1, 0.3, 1), glow 3s ease-in-out infinite;
  font-size: 0.9375rem;
  letter-spacing: -0.01em;
  transition: all 0.3s ease;
  
  // Orange accent for default state (click to start)
  strong {
    color: #e4b17a;
    font-weight: 600;
  }
  
  &::before {
    content: '';
    width: 10px;
    height: 10px;
    background: linear-gradient(135deg, #e4b17a, #c99b69);
    border-radius: 50%;
    margin-right: 0.25rem;
    box-shadow: 0 0 0 2px rgba(228, 177, 122, 0.2),
    0 0 8px rgba(228, 177, 122, 0.4);
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    transition: all 0.3s ease;
  }
  
  // Green accent for active drawing state (click to finish)
  &.active-drawing {
    background: rgba(15, 23, 42, 0.95);
    border: 1px solid rgba(16, 185, 129, 0.2);
    box-shadow: 0 4px 24px -6px rgba(0, 0, 0, 0.3),
    0 12px 40px -8px rgba(0, 0, 0, 0.25),
    0 0 20px rgba(16, 185, 129, 0.1);
    
    strong {
      color: #10b981;
      font-weight: 600;
    }
    
    &::before {
      background: linear-gradient(135deg, #10b981, #059669);
      box-shadow: 0 0 0 2px rgba(16, 185, 129, 0.2),
      0 0 12px rgba(16, 185, 129, 0.4);
      animation: pulseGreen 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    }
  }



  .deselect-btn {
    background: rgba(255, 255, 255, 0.08);
    border: 1px solid rgba(255, 255, 255, 0.12);
    padding: 0.5rem 1.125rem;
    border-radius: 12px;
    color: rgba(255, 255, 255, 0.9);
    font-size: 0.875rem;
    font-weight: 500;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-left: 0.5rem;
    box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.08),
    0 1px 2px rgba(0, 0, 0, 0.2);

    &:hover {
      background: rgba(255, 255, 255, 0.12);
      transform: translateY(-1px);
      box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.12),
      0 4px 8px -2px rgba(0, 0, 0, 0.3);
    }

    &:active {
      transform: translateY(0);
      background: rgba(255, 255, 255, 0.06);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1),
      0 1px 2px rgba(0, 0, 0, 0.2);
    }
  }
}

@keyframes slideUp {
  from {
    transform: translate(-50%, 20px);
    opacity: 0;
  }
  to {
    transform: translate(-50%, 0);
    opacity: 1;
  }
}

@keyframes glow {
  0% {
    box-shadow: 0 4px 24px -6px rgba(0, 0, 0, 0.3),
    0 12px 40px -8px rgba(0, 0, 0, 0.25),
    inset 0 1px 1px rgba(255, 255, 255, 0.1),
    inset 0 -1px 1px rgba(0, 0, 0, 0.1);
  }
  50% {
    box-shadow: 0 4px 24px -6px rgba(0, 0, 0, 0.3),
    0 12px 40px -8px rgba(0, 0, 0, 0.25),
    0 0 20px rgba(16, 185, 129, 0.1),
    inset 0 1px 1px rgba(255, 255, 255, 0.1),
    inset 0 -1px 1px rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0 4px 24px -6px rgba(0, 0, 0, 0.3),
    0 12px 40px -8px rgba(0, 0, 0, 0.25),
    inset 0 1px 1px rgba(255, 255, 255, 0.1),
    inset 0 -1px 1px rgba(0, 0, 0, 0.1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
    box-shadow: 0 0 0 2px rgba(228, 177, 122, 0.2),
    0 0 8px rgba(228, 177, 122, 0.4);
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
    box-shadow: 0 0 0 4px rgba(228, 177, 122, 0.15),
    0 0 12px rgba(228, 177, 122, 0.3);
  }
  100% {
    transform: scale(1);
    opacity: 1;
    box-shadow: 0 0 0 2px rgba(228, 177, 122, 0.2),
    0 0 8px rgba(228, 177, 122, 0.4);
  }
}

@keyframes pulseGreen {
  0% {
    transform: scale(1);
    opacity: 1;
    box-shadow: 0 0 0 2px rgba(16, 185, 129, 0.2),
    0 0 8px rgba(16, 185, 129, 0.4);
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
    box-shadow: 0 0 0 4px rgba(16, 185, 129, 0.15),
    0 0 12px rgba(16, 185, 129, 0.3);
  }
  100% {
    transform: scale(1);
    opacity: 1;
    box-shadow: 0 0 0 2px rgba(16, 185, 129, 0.2),
    0 0 8px rgba(16, 185, 129, 0.4);
  }
}

@keyframes tooltipFadeIn {
  0% { 
    opacity: 0; 
    transform: translateX(-50%) translateY(10px); 
  }
  100% { 
    opacity: 1; 
    transform: translateX(-50%) translateY(0px); 
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes shimmer {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
}