/* Add these CSS animations to your TGroupDetails.scss file */

/* Slide animations for batch navigation */
.slide-in-right {
  animation: slideInRight 0.3s ease-in-out;
}

.slide-in-left {
  animation: slideInLeft 0.3s ease-in-out;
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Animation for the batch navigation dots */
.batch-dot {
  transition: all 0.3s ease;
}

.batch-dot.active {
  transform: scale(1.5);
  background-color: rgb(228, 177, 122);
}

/* Add a subtle animation to the navigation buttons */
.batch-nav-button {
  transition: all 0.2s ease;
}

.batch-nav-button:hover:not(:disabled) {
  transform: scale(1.1);
}

.batch-nav-button:active:not(:disabled) {
  transform: scale(0.95);
}

/* Navigation indicator animation */
.batch-progress-indicator {
  position: relative;
  height: 4px;
  background-color: #f3f4f6;
  border-radius: 2px;
  overflow: hidden;
  margin: 10px 0;
}

.batch-progress-bar {
  position: absolute;
  height: 100%;
  background-color: rgb(228, 177, 122);
  transition: width 0.5s ease;
}