@font-face {
  font-family: 'Google Sans';
  src: url('https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2vgCIhM907w.woff2')
    format('woff2');
}

@keyframes drawingPreview {
  0% {
    stroke-dashoffset: 12;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulseHighlight {
  0% {
    background: rgba(59, 130, 246, 0.1);
  }
  50% {
    background: rgba(59, 130, 246, 0.15);
  }
  100% {
    background: rgba(59, 130, 246, 0.1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.2;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.4;
  }
  100% {
    transform: scale(1);
    opacity: 0.2;
  }
}

@keyframes loadingRing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulseScale {
  0% {
    transform: scale(0.98);
    opacity: 0.8;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.98);
    opacity: 0.8;
  }
}

@keyframes overlayFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes processingPulse {
  0% {
    transform: scale(0.98);
    filter: brightness(0.96);
  }
  50% {
    transform: scale(1);
    filter: brightness(1);
  }
  100% {
    transform: scale(0.98);
    filter: brightness(0.96);
  }
}

@keyframes uploadComplete {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes boxSuccess {
  0% {
    border-color: #e2e8f0;
    background: white;
  }
  100% {
    border-color: #e4b17a;
    background: rgba(228, 177, 122, 0.05);
    border-style: solid;
  }
}

* {
  font-family:
    'Google Sans',
    system-ui,
    -apple-system,
    sans-serif;
}

.app {
  width: 100vw;
  height: 100vh;
  background: #f8fafc;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.split-container {
  display: flex;
  height: calc(100vh - 73px);
  width: 100%;
  background: white;
  overflow: hidden;
}

.upload-panel {
  width: 400px;
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #e2e8f0;
  position: relative;
  overflow: hidden;
}

.panel-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 2rem;
  overflow-y: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.panel-header {
  margin-bottom: 1rem;

  h1 {
    font-size: 1.5rem;
    font-weight: 500;
    color: #1e293b;
    letter-spacing: -0.02em;
    margin-bottom: 0.5rem;
  }

  p {
    color: #64748b;
    font-size: 0.875rem;
    letter-spacing: -0.01em;
    line-height: 1.5;
  }
}

.drawing-status {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: #f8fafc;
  border-radius: 12px;
  margin-top: 1rem;
  transition: all 0.2s ease;

  &.active {
    animation: pulseHighlight 2s ease-in-out infinite;
  }

  .status-icon {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 8px;
    color: #64748b;
    transition: all 0.2s ease;

    &.active {
      color: #3b82f6;
      background: #eff6ff;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .status-text {
    flex: 1;

    h3 {
      font-size: 0.875rem;
      font-weight: 500;
      color: #1e293b;
      margin-bottom: 0.25rem;
    }

    p {
      font-size: 0.75rem;
      color: #64748b;
    }
  }
}

.live-preview {
  margin-top: 1rem;
  padding: 1rem;
  background: #f8fafc;
  border-radius: 12px;
  border: 1px solid #e2e8f0;

  h3 {
    font-size: 0.875rem;
    font-weight: 500;
    color: #1e293b;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .live-indicator {
      width: 8px;
      height: 8px;
      background: #22c55e;
      border-radius: 50%;
      display: inline-block;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: -2px;
        left: -2px;
        right: -2px;
        bottom: -2px;
        background: #22c55e;
        border-radius: 50%;
        animation: pulse 1.5s ease-in-out infinite;
        opacity: 0.2;
      }
    }
  }

  .preview-area {
    aspect-ratio: 1;
    width: 100%;
    background: white;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);

    .preview-box {
      position: absolute;
      background: rgba(59, 130, 246, 0.1);
      border: 2px solid #3b82f6;
      border-radius: 4px;
      transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    }
  }

  .preview-details {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #e2e8f0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;

    .detail-item {
      h4 {
        font-size: 0.75rem;
        color: #64748b;
        margin-bottom: 0.25rem;
      }

      p {
        font-size: 0.875rem;
        color: #1e293b;
        font-weight: 500;
      }
    }
  }
}

// Enhanced gradients and colors
$primary-gradient: linear-gradient(135deg, #3b82f6, #2563eb);
$secondary-gradient: linear-gradient(135deg, #e4b179, #d4956b);
$success-gradient: linear-gradient(135deg, #22c55e, #16a34a);
$error-gradient: linear-gradient(135deg, #ef4444, #dc2626);

// Enhanced animations
@keyframes floatIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.upload-box {
  width: 100%;
  min-height: 300px;
  border: 2px dashed #e2e8f0;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  background: linear-gradient(135deg, #ffffff, #f8fafc);
  box-shadow:
    0 8px 32px rgba(0, 0, 0, 0.05),
    0 16px 48px rgba(0, 0, 0, 0.03);
  padding: 2rem;

  &:hover {
    transform: translateY(-4px);
    box-shadow:
      0 12px 48px rgba(59, 130, 246, 0.1),
      0 24px 64px rgba(59, 130, 246, 0.05);
    border-color: #3b82f6;
    background: linear-gradient(135deg, #f0f9ff, #ffffff);

    .upload-icon {
      transform: translateY(-8px) scale(1.1);
      color: #3b82f6;
    }
  }
}

.hidden {
  display: none;
}

.upload-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 2rem;
  text-align: center;
  width: 100%;

  .upload-icon {
    font-size: 3rem;
    color: #94a3b8;
    margin-bottom: 1.5rem;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  }

  span {
    font-size: 1.25rem;
    font-weight: 600;
    color: #e4b17a;
    // margin-bottom: 0.75rem;
    letter-spacing: -0.02em;
    // background: $primary-gradient;
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
  }

  .upload-hint {
    color: #64748b;
    font-size: 0.875rem;
    letter-spacing: -0.01em;
    line-height: 1.5;
    max-width: 80%;
    margin: 0 auto;
  }

  &:hover .upload-icon {
    color: #edc8a1;
    transform: translateY(-2px);
  }
}

.viewer-panel {
  flex: 1;
  height: calc(100vh - 73px);
  background: #f1f5f9;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  > .final-pdf-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
  }
}

.viewer-tabs {
  padding: 1.5rem 2rem 0;
  display: flex;
  gap: 1rem;
  background: white;
  border-bottom: 1px solid #e2e8f0;
  z-index: 10;

  .tab {
    padding: 0.875rem 2rem;
    border: none;
    background: transparent;
    color: #64748b;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      background: linear-gradient(to bottom, rgba(59, 130, 246, 0.1), transparent);
      opacity: 0;
      transition: opacity 0.2s ease;
    }

    &:hover:not(:disabled) {
      color: #3b82f6;

      &::before {
        opacity: 1;
      }
    }

    &.active {
      color: #3b82f6;
      border-bottom-color: #3b82f6;
      font-weight: 600;

      &::before {
        opacity: 1;
        background: linear-gradient(to bottom, rgba(59, 130, 246, 0.15), transparent);
      }
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &:focus {
      outline: none;
      color: #3b82f6;

      &::before {
        opacity: 1;
      }
    }

    &:active {
      transform: translateY(1px);
    }
  }
}

.final-pdf-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #f8fafc;
  position: relative;
  height: 100%;
  overflow: hidden;
}

.final-pdf-toolbar {
  // padding: 1rem 2rem;
  // background: white;
  // border-bottom: 1px solid #e2e8f0;
  // display: flex;
  // justify-content: flex-end;
  // gap: 1rem;
  // z-index: 10;
  // flex-shrink: 0;
}

.final-pdf-content {
  flex: 1;
  position: relative;
  overflow: hidden;
  background: #f1f5f9;
  min-height: 0;
  display: flex;
  flex-direction: column;
}

.final-pdf-viewer {
  flex: 1;
  width: 100%;
  height: 100%;
  border: none;
  background: #f1f5f9;

  p {
    padding: 2rem;
    text-align: center;
    color: #64748b;
    font-size: 0.875rem;

    a {
      color: #3b82f6;
      text-decoration: none;
      font-weight: 500;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.download-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  background: linear-gradient(135deg, #e4b179, #d4956b);
  color: white;
  border-radius: 68px;
  font-size: 0.875rem;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow:
    inset 0 -2px 4px rgba(0, 0, 0, 0.1),
    inset 0 2px 4px rgba(255, 255, 255, 0.1),
    0 4px 8px -2px rgba(212, 149, 107, 0.3),
    0 8px 16px -4px rgba(212, 149, 107, 0.2);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  position: absolute;
  right: 5%;
  z-index: 999;
  top: 11px;
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.05));
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  &:hover {
    transform: translateY(-1px);
    background: linear-gradient(135deg, #d4956b, #c47a5d);
    box-shadow:
      inset 0 -2px 4px rgba(0, 0, 0, 0.1),
      inset 0 2px 4px rgba(255, 255, 255, 0.1),
      0 6px 12px -2px rgba(212, 149, 107, 0.4),
      0 12px 24px -4px rgba(212, 149, 107, 0.3);

    &::before {
      opacity: 1;
    }
  }

  &:active {
    transform: translateY(0);
  }

  svg {
    width: 16px;
    height: 16px;
    filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.1));
  }
}

.pdf-container {
  position: relative;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50rem;
  width: 100%;
  overflow: hidden;

  &.transitioning {
    transform: scale(0.98);
  }
}

// PDF viewer styles
[class*='rpv-core__viewer'] {
  background: #fdf6f0 !important;
}

[class*='rpv-core__inner-page'] {
  background: transparent !important;
}

[class*='rpv-core__page-layer'] {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08) !important;
  border-radius: 4px;
}

// Hide toolbar and other UI elements
[class*='rpv-core__toolbar'] {
  display: none !important;
}

[class*='rpv-core__menu'] {
  display: none !important;
}

[class*='rpv-core__spinner'] {
  display: none !important;
}

.empty-viewer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #94a3b8;
  gap: 1rem;
  padding: 2rem;
  text-align: center;

  svg {
    width: 48px;
    height: 48px;
    color: #cbd5e1;
    margin-bottom: 1rem;
  }

  h2 {
    font-size: 1.25rem;
    font-weight: 500;
    color: #64748b;
    letter-spacing: -0.02em;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 0.875rem;
    letter-spacing: -0.01em;
    line-height: 1.5;
    max-width: 320px;
  }
}

// Drawing Overlay
.drawing-overlay {
  touch-action: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  will-change: transform;
  display: flex;

  &.drawing-mode {
    cursor: crosshair !important;
    * {
      cursor: crosshair !important;
    }
  }

  &.edit-mode {
    cursor: default;
  }
}

// Drawing Preview
.drawing-preview {
  fill: rgba(0, 123, 255, 0.2);
  stroke: #007bff;
  stroke-width: 2px;
  vector-effect: non-scaling-stroke;
  stroke-dasharray: 6 6;
  animation: drawingPreview 0.5s linear infinite;
  shape-rendering: crispEdges;
  filter: drop-shadow(0 0 1px rgba(66, 133, 244, 0.5));
}

.resize-handle {
  fill: white;
  stroke: #28a745;
  stroke-width: 2px;
  filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1));
  transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: center;
  will-change: transform;
  cursor: pointer;

  &.corner {
    &[data-handle='topLeft'] {
      cursor: nw-resize;
    }

    &[data-handle='topRight'] {
      cursor: ne-resize;
    }

    &[data-handle='bottomLeft'] {
      cursor: sw-resize;
    }

    &[data-handle='bottomRight'] {
      cursor: se-resize;
    }
  }

  &:hover {
    fill: #28a745;
    transform: scale(1.2);
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.15));
  }

  &:active {
    transform: scale(1.1);
    fill: #28a745;
  }
}

.saved-box {
  fill: rgba(40, 167, 69, 0.1);
  stroke: #28a745;
  stroke-width: 2px;
  cursor: move;
  transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: center;
  will-change: transform;

  &.selected {
    fill: rgba(40, 167, 69, 0.15);
    stroke: #28a745;
    stroke-width: 2px;
    filter: drop-shadow(0 0 4px rgba(40, 167, 69, 0.3));
  }

  &:hover:not(.selected) {
    fill: rgba(40, 167, 69, 0.15);
    filter: drop-shadow(0 0 8px rgba(40, 167, 69, 0.2));
  }
}

.saved-box-group {
  &.selected {
    .saved-box {
      pointer-events: all;
    }

    // Show resize guides
    &::after {
      content: '';
      position: absolute;
      inset: 0;
      border: 1px dashed #28a745;
      opacity: 0.5;
      pointer-events: none;
      animation: dashOffset 20s linear infinite;
    }

    .resize-handle {
      opacity: 0;
      transition:
        opacity 0.15s ease,
        transform 0.1s cubic-bezier(0.4, 0, 0.2, 1);
      will-change: transform, opacity;
    }

    &:hover {
      .resize-handle {
        opacity: 1;
      }
    }
  }
}

.drag-handle {
  fill: transparent;
  cursor: move;
  pointer-events: all;
  transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    fill: rgba(40, 167, 69, 0.05);
  }

  &:active {
    fill: rgba(40, 167, 69, 0.1);
    cursor: grabbing;
  }
}

@keyframes dashOffset {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 100;
  }
}

// Add resize guides
.resize-guide {
  stroke: #28a745;
  stroke-width: 1;
  stroke-dasharray: 4 4;
  opacity: 0;
  transition: opacity 0.2s ease;
  pointer-events: none;

  .saved-box-group.selected:hover & {
    opacity: 0.5;
  }
}

// Keystroke styles
.keystroke {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.75rem;
  font-weight: 500;
  color: #64748b;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  margin: 0 0.25rem;
  min-width: 1.5rem;
  justify-content: center;
  text-transform: uppercase;
}

.mode-indicator {
  position: fixed;
  top: 2rem;
  right: 2rem;
  background: white;
  border-radius: 12px;
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 100;
  transition: all 0.2s ease;
  border: 1px solid #e2e8f0;

  &.drawing {
    background: #fdf6f0;
    border-color: #e4b179;

    .mode-icon {
      color: #e4b179;
      background: white;
    }

    .mode-text {
      color: #d4956b;
    }
  }

  &.editing {
    background: #f0fdf4;
    border-color: #86efac;

    .mode-icon {
      color: #22c55e;
      background: white;
    }

    .mode-text {
      color: #15803d;
    }
  }

  .mode-icon {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f8fafc;
    border-radius: 8px;
    color: #64748b;
    transition: all 0.2s ease;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .mode-text {
    font-size: 0.875rem;
    font-weight: 500;
    color: #475569;
    letter-spacing: -0.01em;
  }
}

.drawing-tools {
  position: fixed;
  left: 2rem;
  top: 50%;
  transform: translateY(-50%);
  background: white;
  border-radius: 12px;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 100;
  border: 1px solid #e2e8f0;

  .tool-button {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: #64748b;
    background: transparent;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background: #fdf6f0;
      color: #e4b179;
    }

    &.active {
      background: #fdf6f0;
      color: #e4b179;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.save-dialog {
  position: fixed;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border-radius: 16px;
  padding: 1.25rem;
  box-shadow:
    0 8px 32px rgba(0, 0, 0, 0.08),
    0 2px 8px rgba(0, 0, 0, 0.04),
    0 0 1px rgba(0, 0, 0, 0.12);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  border: 1px solid rgba(226, 232, 240, 0.6);
  // animation: dialogSlideIn 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  min-width: 280px;
  top: 40%;
  left: 50%;

  &::before {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    width: 16px;
    height: 16px;
    background: rgba(255, 255, 255, 0.95);
    border-right: 1px solid rgba(226, 232, 240, 0.6);
    border-bottom: 1px solid rgba(226, 232, 240, 0.6);
    transform-origin: center;
    rotate: 45deg;
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
  }

  .dialog-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    h4 {
      font-size: 1rem;
      font-weight: 600;
      color: #0f172a;
      letter-spacing: -0.02em;
    }

    p {
      font-size: 0.875rem;
      color: #475569;
      letter-spacing: -0.01em;
      line-height: 1.5;
    }
  }

  .dialog-actions {
    display: flex;
    gap: 0.75rem;
    justify-content: flex-end;

    button {
      padding: 0.625rem 1.25rem;
      border-radius: 8px;
      font-size: 0.875rem;
      font-weight: 500;
      transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
      border: none;
      cursor: pointer;
      letter-spacing: -0.01em;
      position: relative;
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 120%;
        height: 120%;
        background: radial-gradient(circle, rgba(255, 255, 255, 0.2), transparent 70%);
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
        pointer-events: none;
      }

      &:hover::before {
        transform: translate(-50%, -50%) scale(2);
      }

      &.save-btn {
        background: linear-gradient(135deg, #3b82f6, #2563eb);
        color: white;
        box-shadow:
          0 2px 4px rgba(37, 99, 235, 0.2),
          0 0 0 1px rgba(59, 130, 246, 0.1);

        &:hover {
          transform: translateY(-1px);
          box-shadow:
            0 4px 8px rgba(37, 99, 235, 0.25),
            0 0 0 1px rgba(59, 130, 246, 0.15);
        }

        &:active {
          transform: translateY(0);
        }
      }

      &.cancel-btn {
        background: #f8fafc;
        color: #475569;
        box-shadow:
          0 1px 2px rgba(0, 0, 0, 0.05),
          0 0 0 1px rgba(0, 0, 0, 0.05);

        &:hover {
          background: #f1f5f9;
          color: #1e293b;
          box-shadow:
            0 2px 4px rgba(0, 0, 0, 0.08),
            0 0 0 1px rgba(0, 0, 0, 0.08);
        }
      }
    }
  }

  &.processing {
    animation: pulseScale 2s ease-in-out infinite;

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      background: rgba(255, 255, 255, 0.7);
      backdrop-filter: blur(8px);
      -webkit-backdrop-filter: blur(8px);
      border-radius: 16px;
      z-index: 2;
    }

    .loading-spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 24px;
      height: 24px;
      z-index: 3;

      &::before,
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 2px solid transparent;
      }

      &::before {
        border-top-color: #e4b179;
        animation: loadingRing 0.8s cubic-bezier(0.4, 0, 0.2, 1) infinite;
      }

      &::after {
        border: 2px solid rgba(228, 177, 121, 0.1);
      }
    }

    .processing-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, calc(-50% + 32px));
      font-size: 0.875rem;
      font-weight: 500;
      color: #e4b179;
      white-space: nowrap;
      z-index: 3;
      letter-spacing: -0.01em;
    }
  }
}
// Variables
$transition-smooth: cubic-bezier(0.4, 0, 0.2, 1);
$transition-bounce: cubic-bezier(0.34, 1.56, 0.64, 1);
$shadow-sm:
  0 1px 2px rgba(0, 0, 0, 0.02),
  0 2px 4px rgba(0, 0, 0, 0.01);
$shadow-md:
  0 4px 8px rgba(0, 0, 0, 0.04),
  0 8px 16px rgba(0, 0, 0, 0.02);
$shadow-lg:
  0 8px 16px rgba(0, 0, 0, 0.06),
  0 16px 32px rgba(0, 0, 0, 0.04);

$success-gradient: linear-gradient(135deg, hsl(142.1 76.2% 36.3%), hsl(142.1 76.2% 28.3%));
$error-gradient: linear-gradient(135deg, hsl(0 84.2% 60.2%), hsl(0 84.2% 50.2%));
$primary-gradient: linear-gradient(135deg, hsl(221.2 83.2% 53.3%), hsl(226.4 70.7% 40.2%));

// Mixins
@mixin glass-effect {
  backdrop-filter: blur(12px) saturate(180%);
  -webkit-backdrop-filter: blur(12px) saturate(180%);
}

@mixin hover-lift {
  transition: all 0.3s $transition-bounce;
  &:hover {
    transform: translateY(-2px);
  }
}

// Processing State Styles
.processing-overlay {
  position: fixed;
  inset: 0;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 2rem;

  .loading-bar {
    position: relative;
    width: 100%;
    height: 3px;
    background: rgba(228, 177, 122, 0.1);
    border-radius: 1.5px;
    overflow: hidden;
    margin-bottom: 3rem;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 30%;
      background: linear-gradient(90deg, transparent, #e4b17a, transparent);
      animation: loadingBar 2s ease-in-out infinite;
    }
  }
}

.processing-header {
  text-align: center;
  margin-bottom: 4rem;
  animation: fadeInUp 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) forwards;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  .processing-icon {
    width: 4rem;
    height: 4rem;
    color: #e4b17a;
    margin-bottom: 2rem;
    animation: floatIcon 3s ease-in-out infinite;
    filter: drop-shadow(0 4px 12px rgba(228, 177, 122, 0.3));
  }

  h2 {
    font-size: 1.75rem;
    font-weight: 600;
    color: #1a1a1a;
    margin-bottom: 1rem;
    letter-spacing: -0.02em;
  }

  p {
    font-size: 1rem;
    color: #4a4a4a;
    max-width: 32rem;
    line-height: 1.7;
    letter-spacing: -0.01em;
  }
}

@keyframes loadingBar {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(400%);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes loadingRing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulseScale {
  0% {
    transform: scale(0.98);
    opacity: 0.8;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.98);
    opacity: 0.8;
  }
}

@keyframes overlayFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes processingPulse {
  0% {
    transform: scale(0.98);
    filter: brightness(0.96);
  }
  50% {
    transform: scale(1);
    filter: brightness(1);
  }
  100% {
    transform: scale(0.98);
    filter: brightness(0.96);
  }
}

@keyframes iconPulse {
  0%,
  100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(0.95);
    opacity: 0.8;
  }
}

@keyframes loadingDots {
  0%,
  100% {
    transform: scale(0.5);
    opacity: 0.5;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes floatIcon {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes stepFadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInUp {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes checkmarkPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
    opacity: 0.8;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
}

// Loading Spinner
.loading-spinner {
  position: relative;
  width: 2rem;
  height: 2rem;

  &::before,
  &::after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 50%;
    border: 2px solid transparent;
  }

  &::before {
    border-top-color: hsl(221.2 83.2% 53.3%);
    animation: spin 0.8s linear infinite;
  }

  &::after {
    border: 2px solid hsl(221.2 83.2% 53.3% / 0.1);
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

// Selection Overlay
.selection-overlay {
  fill: none;
  stroke: hsl(142.1 76.2% 36.3%);
  stroke-width: 2;
  pointer-events: none;
  filter: drop-shadow(0 0 4px hsl(142.1 76.2% 36.3% / 0.3));
}

// Delete Button
.box-delete-button {
  opacity: 0;
  transform: translate(-8px, 8px) scale(0.8);
  transition: all 0.3s $transition-bounce;
  transform-origin: center;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));

  .delete-button-bg {
    fill: white;
    stroke: hsl(0 84.2% 60.2%);
    stroke-width: 2;
    transition: all 0.2s $transition-smooth;
  }

  .delete-button-x {
    stroke: hsl(0 84.2% 60.2%);
    stroke-width: 2;
    stroke-linecap: round;
    transition: all 0.2s $transition-smooth;
  }

  &:hover {
    transform: translate(0, 0) scale(1.1);

    .delete-button-bg {
      fill: hsl(0 84.2% 60.2%);
    }

    .delete-button-x {
      stroke: white;
    }
  }

  &:active {
    transform: translate(0, 0) scale(0.95);
  }
}

// Floating Delete Button
.floating-delete {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 1000;
}

.floating-delete-button {
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.875rem 1.5rem;
  background: $error-gradient;
  color: white;
  border: none;
  border-radius: 4rem;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s $transition-bounce;
  box-shadow:
    inset 0 -2px 4px rgba(0, 0, 0, 0.1),
    inset 0 2px 4px rgba(255, 255, 255, 0.1),
    0 4px 8px -2px hsl(0 84.2% 60.2% / 0.2),
    0 8px 16px -4px hsl(0 84.2% 60.2% / 0.2);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.05));
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow:
      inset 0 -2px 4px rgba(0, 0, 0, 0.1),
      inset 0 2px 4px rgba(255, 255, 255, 0.1),
      0 8px 16px -2px hsl(0 84.2% 60.2% / 0.3),
      0 16px 32px -4px hsl(0 84.2% 60.2% / 0.2);

    &::before {
      opacity: 1;
    }
  }

  &:active {
    transform: translateY(-1px);
  }
}
@keyframes dialogSlideIn {
  0% {
    opacity: 0;
    transform: translate(-50%, -80%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -100%);
  }
}

@keyframes progressSlide {
  0% {
    left: -40%;
  }
  100% {
    left: 100%;
  }
}

.selection-overlay {
  fill: none;
  stroke: #28a745;
  stroke-width: 2;
  pointer-events: none;
  filter: drop-shadow(0 0 4px rgba(40, 167, 69, 0.3));
}

.box-delete-button {
  opacity: 0;
  transform: translate(-8px, 8px) scale(0.8);
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: center;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));

  .delete-button-bg {
    fill: white;
    stroke: #ef4444;
    stroke-width: 2;
    transition: all 0.2s ease;
  }

  .delete-button-x {
    stroke: #ef4444;
    stroke-width: 2;
    stroke-linecap: round;
    transition: all 0.2s ease;
  }

  &:hover {
    transform: translate(0, 0) scale(1.1);

    .delete-button-bg {
      fill: #ef4444;
    }

    .delete-button-x {
      stroke: white;
    }
  }

  &:active {
    transform: translate(0, 0) scale(0.95);
  }
}

.boundary-warning {
  position: fixed;
  background: #fef2f2;
  border: 1px solid #fecaca;
  padding: 0.75rem 1rem;
  border-radius: 12px;
  color: #ef4444;
  font-size: 0.875rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  box-shadow:
    0 4px 6px -1px rgba(239, 68, 68, 0.1),
    0 2px 4px -1px rgba(239, 68, 68, 0.06);
  z-index: 1000;
  pointer-events: none;
  animation: fadeInScale 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  max-width: 300px;
  white-space: nowrap;

  .warning-icon {
    width: 20px;
    height: 20px;
    background: #ef4444;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 14px;
    flex-shrink: 0;
  }

  &::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    background: #fef2f2;
    border-left: 1px solid #fecaca;
    border-bottom: 1px solid #fecaca;
    transform: translate(-50%, -50%) rotate(45deg);
    left: -4px;
    top: 50%;
  }
}

@keyframes fadeInScale {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

// Page Navigation
.page-navigation {
  margin-top: 2rem;
  background: white;
  border-radius: 16px;
  box-shadow:
    0 4px 6px -1px rgba(0, 0, 0, 0.05),
    0 2px 4px -1px rgba(0, 0, 0, 0.03);
  border: 1px solid #e2e8f0;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem;

  .page-info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      font-size: 0.875rem;
      font-weight: 600;
      color: #1e293b;
      letter-spacing: -0.01em;
    }

    p {
      font-size: 0.75rem;
      color: #64748b;
      background: #f8fafc;
      padding: 0.25rem 0.75rem;
      border-radius: 6px;
      border: 1px solid #e2e8f0;
    }
  }

  .page-controls {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 1.5rem;

    .page-button {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background: #f8fafc;
      border-radius: 10px;
      color: #64748b;
      cursor: pointer;
      transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
      border: 1px solid #e2e8f0;

      &:hover:not(:disabled) {
        background: #fdf6f0;
        color: #e4b179;
        border-color: #e4b179;
        transform: translateY(-1px);
      }

      &:active:not(:disabled) {
        transform: translateY(0);
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      svg {
        width: 20px;
        height: 20px;
        stroke-width: 2px;
      }
    }

    .page-input-container {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      background: #f8fafc;
      padding: 0.5rem 0.75rem;
      border-radius: 10px;
      border: 1px solid #e2e8f0;

      input {
        width: 40px;
        border: none;
        background: transparent;
        font-size: 0.875rem;
        font-weight: 500;
        color: #1e293b;
        text-align: center;
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        &:focus {
          outline: none;
        }
      }

      .page-separator {
        color: #94a3b8;
        font-size: 0.875rem;
      }

      .total-pages {
        color: #64748b;
        font-size: 0.875rem;
        font-weight: 500;
      }
    }
  }

  .page-thumbnails {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    gap: 1rem;
    max-height: 300px;
    overflow-y: auto;
    padding: 0.75rem;
    margin: 0 -0.75rem;
    scrollbar-width: thin;
    scrollbar-color: #cbd5e1 transparent;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #cbd5e1;
      border-radius: 3px;
    }

    .page-thumbnail {
      position: relative;
      aspect-ratio: 0.707;
      border: none;
      background: #f8fafc;
      border-radius: 8px;
      color: #64748b;
      cursor: pointer;
      transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
      border: 1px solid #e2e8f0;
      padding: 0;
      overflow: hidden;

      &:hover:not(:disabled) {
        transform: translateY(-2px);
        border-color: #e4b179;
        box-shadow:
          0 4px 12px rgba(228, 177, 121, 0.1),
          0 2px 4px rgba(228, 177, 121, 0.06);
      }

      &:active:not(:disabled) {
        transform: translateY(-1px);
      }

      &.active {
        border-color: #e4b179;
        box-shadow:
          0 0 0 2px #fdf6f0,
          0 4px 12px rgba(228, 177, 121, 0.15);

        .thumbnail-container::after {
          opacity: 1;
        }
      }

      &.has-selections {
        .thumbnail-container::before {
          content: '';
          position: absolute;
          inset: 4px;
          border: 1px dashed #e4b179;
          border-radius: 4px;
          opacity: 0.4;
          pointer-events: none;
          z-index: 2;
        }
      }

      .thumbnail-container {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;

        &::after {
          content: '';
          position: absolute;
          inset: 0;
          background: linear-gradient(135deg, rgba(228, 177, 121, 0.1), rgba(228, 177, 121, 0.05));
          opacity: 0;
          transition: opacity 0.2s ease;
        }

        .page-preview {
          width: 100%;
          height: 100%;
          object-fit: contain;
          background: white;
        }
      }

      .thumbnail-placeholder {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(90deg, #fdf6f0 25%, #f8fafc 50%, #fdf6f0 75%);

        .page-number {
          font-size: 0.75rem;
          font-weight: 500;
          color: #64748b;
        }
      }

      .selection-indicator {
        position: absolute;
        top: -6px;
        right: -6px;
        background: #e4b179;
        color: white;
        font-size: 0.625rem;
        font-weight: 600;
        min-width: 16px;
        height: 16px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 4px;
        border: 2px solid white;
        box-shadow: 0 2px 4px rgba(228, 177, 121, 0.2);
        z-index: 3;
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}

// Add loading animation for thumbnails
@keyframes thumbnailLoading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.thumbnail-placeholder {
  animation: thumbnailLoading 1.5s ease-in-out infinite;
  background: linear-gradient(90deg, #fdf6f0 25%, #f8fafc 50%, #fdf6f0 75%);
  background-size: 200% 100%;
}

// Add transition styles for page changes
.drawing-overlay {
  &.transitioning {
    opacity: 0;
    transition: opacity 0.3s ease;
  }
}

.pdf-container {
  position: relative;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding-top: 8%;
  min-height: 60vh;

  &.transitioning {
    transform: scale(0.98);
  }
}

.pdf-image-container {
  position: relative;
  max-height: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdf-image {
  max-height: 100vh;
  max-width: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
}

.panel-title-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.panel-title {
  font-size: 1.25rem;
  font-weight: 500;
  color: #64748b;
  letter-spacing: -0.02em;
  margin-bottom: 0.5rem;
}

.panel-title-button-container {
  display: flex;
  gap: 12px;
  align-items: center;
}

.panel-title-button {
  padding: 8px 16px;
  border: none;
  background: linear-gradient(135deg, #e4b179, #d4956b);
  color: white;
  border-radius: 60px;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow:
    inset 0 -2px 4px rgba(0, 0, 0, 0.2),
    inset 0 2px 4px rgba(255, 255, 255, 0.2),
    0 4px 8px -2px rgba(228, 177, 121, 0.3),
    0 8px 16px -4px rgba(228, 177, 121, 0.2);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  position: relative;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 8px;
    padding: 2px;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.05));
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
  }

  &:hover {
    transform: translateY(-2px);
    background: linear-gradient(135deg, #d4956b, #c47a5d);
    box-shadow:
      inset 0 -2px 4px rgba(0, 0, 0, 0.2),
      inset 0 2px 4px rgba(255, 255, 255, 0.2),
      0 8px 16px -4px rgba(228, 177, 121, 0.4),
      0 12px 24px -8px rgba(228, 177, 121, 0.3);
  }

  &:active {
    transform: translateY(1px);
    background: linear-gradient(135deg, #c47a5d, #b4604f);
    box-shadow:
      inset 0 2px 4px rgba(0, 0, 0, 0.3),
      inset 0 -1px 2px rgba(255, 255, 255, 0.1),
      0 2px 4px -2px rgba(228, 177, 121, 0.2),
      0 4px 8px -4px rgba(228, 177, 121, 0.1);
  }

  &:focus {
    outline: none;
    box-shadow:
      inset 0 -2px 4px rgba(0, 0, 0, 0.2),
      inset 0 2px 4px rgba(255, 255, 255, 0.2),
      0 4px 8px -2px rgba(228, 177, 121, 0.3),
      0 8px 16px -4px rgba(228, 177, 121, 0.2),
      0 0 0 2px rgba(228, 177, 121, 0.4),
      0 0 0 4px rgba(228, 177, 121, 0.1);
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    transform: none;
    background: linear-gradient(135deg, #94a3b8, #64748b);
    box-shadow:
      inset 0 -1px 2px rgba(0, 0, 0, 0.1),
      inset 0 1px 2px rgba(255, 255, 255, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.1);
  }
}

.main-navigation {
  padding: 1rem 2rem;
  background: white;
  border-bottom: 1px solid #e2e8f0;
  display: flex;
  gap: 1rem;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(
      90deg,
      rgba(226, 232, 240, 0) 0%,
      rgba(226, 232, 240, 1) 15%,
      rgba(226, 232, 240, 1) 85%,
      rgba(226, 232, 240, 0) 100%
    );
  }

  .nav-tab {
    padding: 0.75rem 1.5rem;
    background: transparent;
    border: none;
    color: #64748b;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    position: relative;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 8px;

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      background: linear-gradient(135deg, rgba(59, 130, 246, 0.1), rgba(59, 130, 246, 0.05));
      border-radius: 8px;
      opacity: 0;
      transition: opacity 0.2s ease;
    }

    &:hover:not(.active) {
      color: #e4b179;

      &::before {
        opacity: 1;
      }
    }

    &.active {
      background: linear-gradient(135deg, #e4b179, #d4956b);
      color: white;
      box-shadow:
        0 4px 6px -1px rgba(228, 177, 121, 0.1),
        0 2px 4px -1px rgba(228, 177, 121, 0.06),
        inset 0 1px 0 rgba(255, 255, 255, 0.1);

      &:hover {
        background: linear-gradient(135deg, #d4956b, #c47a5d);
      }

      &:active {
        background: linear-gradient(135deg, #c47a5d, #b4604f);
        transform: translateY(1px);
      }
    }
  }
}

.rubric-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #f8fafc;
  height: 100%;
  overflow: hidden;

  .rubric-header {
    padding: 2rem;
    background: white;
    border-bottom: 1px solid #e2e8f0;

    h2 {
      font-size: 1.5rem;
      font-weight: 600;
      color: #1e293b;
      margin-bottom: 0.5rem;
      letter-spacing: -0.02em;
    }

    p {
      color: #64748b;
      font-size: 0.875rem;
      line-height: 1.5;
    }
  }

  .rubric-content {
    flex: 1;
    padding: 2rem;
    overflow-y: auto;

    .rubric-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      gap: 1.5rem;

      .rubric-card {
        background: white;
        border-radius: 12px;
        padding: 1.5rem;
        box-shadow:
          0 4px 6px -1px rgba(0, 0, 0, 0.1),
          0 2px 4px -1px rgba(0, 0, 0, 0.06);
        transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
        border: 1px solid #e2e8f0;

        &:hover {
          transform: translateY(-2px);
          box-shadow:
            0 10px 15px -3px rgba(0, 0, 0, 0.1),
            0 4px 6px -2px rgba(0, 0, 0, 0.05);
        }

        h3 {
          font-size: 1rem;
          font-weight: 600;
          color: #1e293b;
          margin-bottom: 1rem;
        }

        .score-input {
          display: flex;
          align-items: center;
          gap: 1rem;
          margin-bottom: 1rem;

          input {
            flex: 1;
            padding: 0.5rem;
            border: 1px solid #e2e8f0;
            border-radius: 6px;
            font-size: 0.875rem;
            transition: all 0.2s ease;

            &:focus {
              outline: none;
              border-color: #e4b179;
              box-shadow: 0 0 0 3px rgba(228, 177, 121, 0.1);
            }
          }
        }

        .feedback-textarea {
          width: 100%;
          min-height: 100px;
          padding: 0.75rem;
          border: 1px solid #e2e8f0;
          border-radius: 6px;
          font-size: 0.875rem;
          resize: vertical;
          transition: all 0.2s ease;

          &:focus {
            outline: none;
            border-color: #e4b179;
            box-shadow: 0 0 0 3px rgba(228, 177, 121, 0.1);
          }
        }
      }
    }
  }
}

.solution-rubric-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #f8fafc;

  .upload-section {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;

    .upload-box {
      width: 100%;
      max-width: 480px;
    }
  }

  .solution-content {
    flex: 1;
    display: flex;
    height: 100%;
    overflow: hidden;

    .pdf-view {
      flex: 1;
      height: 100%;
      background: #f1f5f9;
      border-right: 1px solid #e2e8f0;
      overflow: hidden;
      display: flex;

      object {
        flex: 1;
        border: none;
      }
    }

    .rubric-section {
      width: 400px;
      height: 100%;
      display: flex;
      flex-direction: column;
      background: white;

      .rubric-header {
        padding: 1.5rem;
        background: white;
        border-bottom: 1px solid #e2e8f0;

        h2 {
          font-size: 1.25rem;
          font-weight: 600;
          color: #8c6b4a;
          margin-bottom: 0.5rem;
          letter-spacing: -0.02em;
        }

        p {
          color: #a17c57;
          font-size: 0.875rem;
          line-height: 1.5;
        }
      }

      .rubric-content {
        flex: 1;
        overflow-y: auto;
        padding: 1.5rem;

        .rubric-grid {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .rubric-card {
            background: white;
            border-radius: 12px;
            padding: 1.25rem;
            box-shadow:
              0 2px 4px -1px rgba(0, 0, 0, 0.06),
              0 1px 2px -1px rgba(0, 0, 0, 0.03);
            border: 1px solid #e2e8f0;
            transition: all 0.2s ease;

            &:hover {
              border-color: #e4b17a;
              box-shadow:
                0 4px 6px -1px rgba(228, 177, 121, 0.08),
                0 2px 4px -1px rgba(228, 177, 121, 0.04);
              transform: translateY(-1px);
            }

            h3 {
              font-size: 0.875rem;
              font-weight: 600;
              color: #8c6b4a;
              margin-bottom: 0.75rem;
            }

            .score-input {
              display: flex;
              align-items: center;
              gap: 0.75rem;
              margin-bottom: 0.75rem;

              input {
                flex: 1;
                padding: 0.5rem;
                border: 1px solid #e2e8f0;
                border-radius: 6px;
                font-size: 0.875rem;
                transition: all 0.2s ease;

                &:focus {
                  outline: none;
                  border-color: #e4b17a;
                  box-shadow: 0 0 0 3px rgba(228, 177, 121, 0.1);
                }
              }

              span {
                color: #a17c57;
                font-size: 0.875rem;
                font-weight: 500;
              }
            }

            .feedback-textarea {
              width: 100%;
              min-height: 80px;
              padding: 0.75rem;
              border: 1px solid #e2e8f0;
              border-radius: 6px;
              font-size: 0.875rem;
              resize: vertical;
              transition: all 0.2s ease;

              &:focus {
                outline: none;
                border-color: #e4b17a;
                box-shadow: 0 0 0 3px rgba(228, 177, 121, 0.1);
              }
            }
          }
        }

        .total-score {
          margin-top: 1.5rem;
          padding: 1rem;
          background: #fdf8f3;
          border-radius: 10px;
          border: 1px solid #e4b17a;
          text-align: center;

          h3 {
            font-size: 1rem;
            font-weight: 600;
            color: #e4b17a;
            letter-spacing: -0.01em;
          }
        }
      }
    }
  }
}

.ai-rubric-generator {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #fdf8f3;
  overflow: hidden;

  .ai-rubric-header {
    padding: 1.5rem 2rem;
    background: white;
    border-bottom: 1px solid rgba(228, 177, 121, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;

    .header-content {
      h2 {
        font-size: 1.5rem;
        font-weight: 600;
        color: #8c6b4a;
        margin-bottom: 0.5rem;
        letter-spacing: -0.02em;
      }

      p {
        color: #a17c57;
        font-size: 0.875rem;
        line-height: 1.5;
      }
    }

    .generate-button {
      display: inline-flex;
      align-items: center;
      gap: 0.75rem;
      padding: 0.875rem 1.5rem;
      background: linear-gradient(135deg, #e4b17a, #c99b69);
      color: white;
      border: none;
      border-radius: 10px;
      font-size: 0.875rem;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
      box-shadow:
        0 4px 6px -1px rgba(228, 177, 121, 0.1),
        0 2px 4px -1px rgba(228, 177, 121, 0.06);

      &:hover:not(:disabled) {
        transform: translateY(-1px);
        background: linear-gradient(135deg, #c99b69, #b38a5e);
        box-shadow:
          0 6px 8px -1px rgba(228, 177, 121, 0.2),
          0 4px 6px -1px rgba(228, 177, 121, 0.1);
      }
    }
  }

  .ai-rubric-content {
    flex: 1;
    padding: 2rem;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .criteria-selection {
      background: white;
      border-radius: 16px;
      padding: 1.5rem;
      box-shadow:
        0 4px 6px -1px rgba(228, 177, 121, 0.05),
        0 2px 4px -1px rgba(228, 177, 121, 0.03);
      border: 1px solid rgba(228, 177, 121, 0.2);

      h3 {
        font-size: 1.125rem;
        font-weight: 600;
        color: #8c6b4a;
        margin-bottom: 0.5rem;
      }

      p {
        color: #a17c57;
        font-size: 0.875rem;
        margin-bottom: 1.5rem;
      }
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeInScale {
  from {
    opacity: 0;
    transform: scale(0.98);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.rubric-viewer {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: white;

  .viewer-sections {
    flex: 1;
    display: flex;
    overflow: hidden;
  }

  .viewer-header {
    background: linear-gradient(180deg, #ffffff, #f8fafc);
    border-bottom: 1px solid rgba(226, 232, 240, 0.8);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);

    .tab {
      position: relative;
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        inset: 0;
        background: linear-gradient(135deg, rgba(59, 130, 246, 0.1), transparent);
        opacity: 0;
        transition: opacity 0.3s ease;
      }

      &:hover::before {
        opacity: 1;
      }

      &.active {
        &::before {
          background: linear-gradient(135deg, rgba(59, 130, 246, 0.15), rgba(59, 130, 246, 0.05));
          opacity: 1;
        }
      }
    }
  }
}

.question-card {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid rgba(226, 232, 240, 0.8);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.03);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.05);
  }

  &.approved {
    border-color: rgba(34, 197, 94, 0.4);
    background: linear-gradient(135deg, rgba(240, 253, 244, 0.8), rgba(240, 253, 244, 0.4));
  }

  &.rejected {
    border-color: rgba(239, 68, 68, 0.4);
    background: linear-gradient(135deg, rgba(254, 242, 242, 0.8), rgba(254, 242, 242, 0.4));
  }

  .approval-actions {
    button {
      backdrop-filter: blur(8px);
      -webkit-backdrop-filter: blur(8px);
      transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

      &.approve-btn {
        &:hover,
        &.active {
          background: $success-gradient;
          border-color: transparent;
          transform: translateY(-1px);
          box-shadow: 0 4px 12px rgba(34, 197, 94, 0.2);
        }
      }

      &.reject-btn {
        &:hover,
        &.active {
          background: $error-gradient;
          border-color: transparent;
          transform: translateY(-1px);
          box-shadow: 0 4px 12px rgba(239, 68, 68, 0.2);
        }
      }
    }
  }
}

.floating-delete {
  position: fixed;
  bottom: 32px;
  right: 32px;
  z-index: 1000;
}

.floating-delete-button {
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.875rem 1.5rem;
  background: linear-gradient(135deg, #ef4444, #dc2626);
  color: white;
  border: none;
  border-radius: 68px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow:
    inset 0 -2px 4px rgba(0, 0, 0, 0.2),
    inset 0 2px 4px rgba(255, 255, 255, 0.2),
    0 4px 8px -2px rgba(239, 68, 68, 0.3),
    0 8px 16px -4px rgba(239, 68, 68, 0.2),
    0 -2px 4px -2px rgba(255, 255, 255, 0.2);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.05));
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  &:hover {
    transform: translateY(-2px);
    background: linear-gradient(135deg, #dc2626, #b91c1c);
    box-shadow:
      inset 0 -2px 4px rgba(0, 0, 0, 0.2),
      inset 0 2px 4px rgba(255, 255, 255, 0.2),
      0 6px 12px -2px rgba(239, 68, 68, 0.4),
      0 12px 24px -4px rgba(239, 68, 68, 0.3);

    &::before {
      opacity: 1;
    }
  }

  &:active {
    transform: translateY(1px);
    background: linear-gradient(135deg, #b91c1c, #991b1b);
    box-shadow:
      inset 0 2px 4px rgba(0, 0, 0, 0.3),
      inset 0 -1px 2px rgba(255, 255, 255, 0.1),
      0 2px 4px -2px rgba(239, 68, 68, 0.2),
      0 4px 8px -4px rgba(239, 68, 68, 0.1);
  }

  svg {
    width: 16px;
    height: 16px;
    filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.1));
    transition: transform 0.2s ease;
  }

  &:hover svg {
    transform: scale(1.1);
  }

  &:active svg {
    transform: scale(0.95);
  }
}

// Add these styles
.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 2rem;
  background: white;
  border-bottom: 1px solid #e2e8f0;
  height: 73px;

  h1 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #1e293b;
    letter-spacing: -0.02em;
  }

  .secondary-button {
    display: inline-flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem 1.25rem;
    background: white;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    color: #64748b;
    font-size: 0.875rem;
    font-weight: 500;
    text-decoration: none;
    transition: all 0.2s;

    &:hover {
      background: #f8fafc;
      border-color: #cbd5e1;
      color: #475569;
    }
  }
}

.questions-view {
  // ... existing code ...

  .questions-header {
    display: flex;
    justify-content: flex-end;
    padding: 0 1rem 1.5rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid hsl(214.3 31.8% 91.4% / 0.5);

    .approve-all-btn {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      padding: 0.75rem 1.25rem;
      background: $success-gradient;
      color: white;
      border: none;
      border-radius: 0.75rem;
      font-weight: 500;
      font-size: 0.9375rem;
      letter-spacing: -0.01em;
      transition: all 0.3s $transition-bounce;
      cursor: pointer;
      box-shadow:
        0 2px 4px hsl(142.1 76.2% 36.3% / 0.2),
        0 4px 8px hsl(142.1 76.2% 36.3% / 0.1);

      .icon-wrapper {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }

      svg {
        stroke-width: 2;
        transition: transform 0.3s $transition-bounce;
      }

      &:hover {
        transform: translateY(-2px);
        box-shadow:
          0 4px 8px hsl(142.1 76.2% 36.3% / 0.2),
          0 8px 16px hsl(142.1 76.2% 36.3% / 0.2);

        svg {
          transform: scale(1.1);
        }
      }

      &:active {
        transform: translateY(-1px);
      }

      &::before {
        content: '';
        position: absolute;
        inset: 0;
        background: linear-gradient(135deg, hsl(0 0% 100% / 0.2), transparent);
        opacity: 0;
        transition: opacity 0.3s ease;
        border-radius: inherit;
      }

      &:hover::before {
        opacity: 1;
      }
    }
  }

  // ... existing code ...
  // ... existing code ...
}

.action-buttons {
  display: flex;
  align-items: center;
  gap: 0.75rem;

  .edit-btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 0.625rem;
    border: none;
    background: hsl(221.2 83.2% 53.3% / 0.1);
    color: hsl(221.2 83.2% 53.3%);
    transition: all 0.2s ease;
    cursor: pointer;

    &:hover {
      background: hsl(221.2 83.2% 53.3%);
      color: white;
      transform: translateY(-1px);
    }

    svg {
      width: 1.125rem;
      height: 1.125rem;
      stroke-width: 2.5;
    }
  }
}

.approval-actions {
  display: flex;
  gap: 0.5rem;
  perspective: 800px;

  button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.75rem;
    height: 2.75rem;
    border-radius: 0.75rem;
    border: none;
    background: white;
    color: hsl(215.4 16.3% 46.9%);
    transition: all 0.2s ease;
    cursor: pointer;
    box-shadow:
      0 0 0 1px hsl(214.3 31.8% 91.4%),
      0 1px 2px hsl(0 0% 0% / 0.05);

    svg {
      width: 1.25rem;
      height: 1.25rem;
      stroke-width: 2.5;
      transition: transform 0.2s ease;
    }

    &.approve-btn {
      &:hover {
        background: hsl(142.1 76.2% 36.3%);
        color: white;
        transform: translateY(-1px);
        box-shadow:
          0 0 0 1px hsl(142.1 76.2% 36.3%),
          0 4px 8px hsl(142.1 76.2% 36.3% / 0.25);

        svg {
          transform: scale(1.1);
        }
      }

      &.active {
        background: hsl(142.1 76.2% 36.3%);
        color: white;
        box-shadow:
          0 0 0 1px hsl(142.1 76.2% 36.3%),
          0 1px 2px hsl(142.1 76.2% 36.3% / 0.25);
      }
    }

    &.reject-btn {
      &:hover {
        background: hsl(0 84.2% 60.2%);
        color: white;
        transform: translateY(-1px);
        box-shadow:
          0 0 0 1px hsl(0 84.2% 60.2%),
          0 4px 8px hsl(0 84.2% 60.2% / 0.25);

        svg {
          transform: scale(1.1);
        }
      }

      &.active {
        background: hsl(0 84.2% 60.2%);
        color: white;
        box-shadow:
          0 0 0 1px hsl(0 84.2% 60.2%),
          0 1px 2px hsl(0 84.2% 60.2% / 0.25);
      }
    }
  }
}

.questions-header {
  display: flex;
  justify-content: flex-end;
  padding: 0 1rem 1.5rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid hsl(214.3 31.8% 91.4% / 0.5);

  .bulk-action-btn {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.875rem 1.5rem;
    border: none;
    border-radius: 0.75rem;
    font-weight: 600;
    font-size: 0.9375rem;
    letter-spacing: -0.01em;
    transition: all 0.2s ease;
    cursor: pointer;

    // Default state (Approve All)
    background: hsl(142.1 76.2% 36.3%);
    color: white;
    box-shadow:
      0 0 0 1px hsl(142.1 76.2% 36.3%),
      0 1px 2px hsl(142.1 76.2% 36.3% / 0.1);

    .icon-wrapper {
      display: flex;
      align-items: center;
      gap: 0.625rem;

      svg {
        width: 1.125rem;
        height: 1.125rem;
        stroke-width: 2.5;
        transition: all 0.2s ease;
      }
    }

    // Hover state for Approve All
    &:hover:not(.undo) {
      transform: translateY(-1px);
      box-shadow:
        0 0 0 1px hsl(142.1 76.2% 36.3%),
        0 4px 8px hsl(142.1 76.2% 36.3% / 0.25);

      svg {
        transform: scale(1.1);
      }
    }

    // Undo state
    &.undo {
      background: hsl(0 84.2% 60.2%);
      color: white;
      box-shadow:
        0 0 0 1px hsl(0 84.2% 60.2%),
        0 1px 2px hsl(0 84.2% 60.2% / 0.1);

      &:hover {
        transform: translateY(-1px);
        box-shadow:
          0 0 0 1px hsl(0 84.2% 60.2%),
          0 4px 8px hsl(0 84.2% 60.2% / 0.25);

        svg {
          transform: scale(1.1) rotate(90deg);
        }
      }

      svg {
        transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
      }
    }

    &:active {
      transform: translateY(0);
    }
  }
}

@keyframes animate-undo {
  0% {
    transform: translateY(0) scale(1);
  }
  20% {
    transform: translateY(-2px) scale(1.02);
  }
  40% {
    transform: translateY(1px) scale(0.98);
  }
  100% {
    transform: translateY(0) scale(1);
  }
}

.animate-undo {
  animation: animate-undo 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
}

// ... existing code ...

// Modern design tokens
$shadow-sm: 0 1px 2px hsl(0 0% 0% / 0.05);
$shadow-md:
  0 1px 3px hsl(0 0% 0% / 0.1),
  0 1px 2px -1px hsl(0 0% 0% / 0.1);
$shadow-lg:
  0 4px 6px -1px hsl(0 0% 0% / 0.1),
  0 2px 4px -2px hsl(0 0% 0% / 0.1);
$ring-offset: 2px;
$ring-color: hsl(221.2 83.2% 53.3% / 0.3);

// Refined transitions
$transition-fast: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
$transition-smooth: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
$transition-bounce: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);

.questions-header {
  position: sticky;
  top: 0;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  padding: 1.25rem;
  background: hsl(0 0% 100% / 0.8);
  backdrop-filter: blur(8px);
  border-bottom: 1px solid hsl(214.3 31.8% 91.4%);

  .bulk-action-btn {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    height: 2.5rem;
    padding: 0 1.25rem;
    background: hsl(0 0% 100%);
    color: hsl(222.2 47.4% 11.2%);
    border: 1px solid hsl(214.3 31.8% 91.4%);
    border-radius: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: -0.01em;
    transition: $transition-smooth;
    user-select: none;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      inset: -$ring-offset;
      border-radius: calc(0.5rem + $ring-offset);
      transition: $transition-fast;
      opacity: 0;
    }

    .icon-wrapper {
      display: inline-flex;
      align-items: center;
      gap: 0.375rem;

      svg {
        width: 1rem;
        height: 1rem;
        stroke-width: 2;
        transition: $transition-bounce;
      }
    }

    // Default state (Approve All)
    &:not(.undo) {
      background: hsl(142.1 76.2% 36.3%);
      border-color: hsl(142.1 76.2% 36.3%);
      color: hsl(0 0% 100%);

      &:hover {
        background: hsl(142.1 76.2% 32.3%);
        transform: translateY(-1px);
        box-shadow: $shadow-lg;

        svg {
          transform: scale(1.15);
        }
      }

      &:focus-visible {
        outline: none;
        &::before {
          box-shadow:
            0 0 0 2px hsl(0 0% 100%),
            0 0 0 4px hsl(142.1 76.2% 36.3% / 0.5);
          opacity: 1;
        }
      }
    }

    // Undo state
    &.undo {
      background: hsl(0 84.2% 60.2%);
      border-color: hsl(0 84.2% 60.2%);
      color: hsl(0 0% 100%);

      &:hover {
        background: hsl(0 84.2% 56.2%);
        transform: translateY(-1px);
        box-shadow: $shadow-lg;

        svg {
          transform: rotate(90deg) scale(1.15);
        }
      }

      &:focus-visible {
        outline: none;
        &::before {
          box-shadow:
            0 0 0 2px hsl(0 0% 100%),
            0 0 0 4px hsl(0 84.2% 60.2% / 0.5);
          opacity: 1;
        }
      }
    }

    &:active {
      transform: translateY(0);
    }
  }
}

.action-buttons {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .edit-btn {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2.25rem;
    height: 2.25rem;
    background: hsl(0 0% 100%);
    border: 1px solid hsl(214.3 31.8% 91.4%);
    border-radius: 0.5rem;
    color: hsl(215.4 16.3% 46.9%);
    transition: $transition-smooth;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      inset: -$ring-offset;
      border-radius: calc(0.5rem + $ring-offset);
      transition: $transition-fast;
      opacity: 0;
    }

    svg {
      width: 1rem;
      height: 1rem;
      stroke-width: 2;
      transition: $transition-bounce;
    }

    &:hover {
      background: hsl(221.2 83.2% 53.3% / 0.1);
      border-color: hsl(221.2 83.2% 53.3%);
      color: hsl(221.2 83.2% 53.3%);
      transform: translateY(-1px);

      svg {
        transform: scale(1.15);
      }
    }

    &:focus-visible {
      outline: none;
      &::before {
        box-shadow:
          0 0 0 2px hsl(0 0% 100%),
          0 0 0 4px $ring-color;
        opacity: 1;
      }
    }
  }
}

.approval-actions {
  display: flex;
  gap: 0.375rem;

  button {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2.25rem;
    height: 2.25rem;
    background: hsl(0 0% 100%);
    border: 1px solid hsl(214.3 31.8% 91.4%);
    border-radius: 0.5rem;
    color: hsl(215.4 16.3% 46.9%);
    transition: $transition-smooth;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      inset: -$ring-offset;
      border-radius: calc(0.5rem + $ring-offset);
      transition: $transition-fast;
      opacity: 0;
    }

    svg {
      width: 1.125rem;
      height: 1.125rem;
      stroke-width: 2;
      transition: $transition-bounce;
    }

    &.approve-btn {
      &:hover:not(.active) {
        background: hsl(142.1 76.2% 36.3% / 0.1);
        border-color: hsl(142.1 76.2% 36.3%);
        color: hsl(142.1 76.2% 36.3%);
        transform: translateY(-1px);

        svg {
          transform: scale(1.15);
        }
      }

      &.active {
        background: hsl(142.1 76.2% 36.3%);
        border-color: hsl(142.1 76.2% 36.3%);
        color: hsl(0 0% 100%);
      }

      &:focus-visible {
        outline: none;
        &::before {
          box-shadow:
            0 0 0 2px hsl(0 0% 100%),
            0 0 0 4px hsl(142.1 76.2% 36.3% / 0.5);
          opacity: 1;
        }
      }
    }

    &.reject-btn {
      &:hover:not(.active) {
        background: hsl(0 84.2% 60.2% / 0.1);
        border-color: hsl(0 84.2% 60.2%);
        color: hsl(0 84.2% 60.2%);
        transform: translateY(-1px);

        svg {
          transform: scale(1.15);
        }
      }

      &.active {
        background: hsl(0 84.2% 60.2%);
        border-color: hsl(0 84.2% 60.2%);
        color: hsl(0 0% 100%);
      }

      &:focus-visible {
        outline: none;
        &::before {
          box-shadow:
            0 0 0 2px hsl(0 0% 100%),
            0 0 0 4px hsl(0 84.2% 60.2% / 0.5);
          opacity: 1;
        }
      }
    }
  }
}

.status-badge {
  display: inline-flex;
  align-items: center;
  gap: 0.375rem;
  height: 1.5rem;
  padding: 0 0.5rem;
  border-radius: 0.375rem;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: -0.01em;
  transition: $transition-smooth;

  svg {
    width: 0.875rem;
    height: 0.875rem;
    stroke-width: 2.5;
  }

  &.approved {
    background: hsl(142.1 76.2% 36.3% / 0.1);
    color: hsl(142.1 76.2% 36.3%);
  }

  &.rejected {
    background: hsl(0 84.2% 60.2% / 0.1);
    color: hsl(0 84.2% 60.2%);
  }
}

// ... rest of your existing styles ...

// Design tokens
$shadow-sm: 0 1px 2px hsl(0 0% 0% / 0.05);
$shadow-md:
  0 1px 3px hsl(0 0% 0% / 0.1),
  0 1px 2px -1px hsl(0 0% 0% / 0.1);
$shadow-lg:
  0 4px 6px -1px hsl(0 0% 0% / 0.1),
  0 2px 4px -2px hsl(0 0% 0% / 0.1);

// Colors
$success-base: hsl(142.1 76.2% 36.3%);
$success-hover: hsl(142.1 76.2% 32.3%);
$success-light: hsl(142.1 76.2% 36.3% / 0.1);
$error-base: hsl(0 84.2% 60.2%);
$error-hover: hsl(0 84.2% 56.2%);
$error-light: hsl(0 84.2% 60.2% / 0.1);

// Transitions
$transition-fast: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
$transition-smooth: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
$transition-bounce: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);

.approval-actions {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  button {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    padding: 0;
    background: white;
    border: 1px solid hsl(214.3 31.8% 91.4%);
    border-radius: 0.5rem;
    color: hsl(215.4 16.3% 46.9%);
    transition: $transition-smooth;
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: transparent;

    &::before {
      content: '';
      position: absolute;
      inset: -2px;
      border-radius: 0.625rem;
      transition: $transition-fast;
      opacity: 0;
      pointer-events: none;
    }

    svg {
      width: 1.25rem;
      height: 1.25rem;
      stroke-width: 2;
      transition: $transition-bounce;
    }

    &.approve-btn {
      &:hover:not(.active) {
        background: $success-light;
        border-color: $success-base;
        color: $success-base;
        transform: translateY(-1px);
        box-shadow: $shadow-sm;

        svg {
          transform: scale(1.15);
        }
      }

      &.active {
        background: $success-base;
        border-color: $success-base;
        color: white;
        box-shadow: 0 0 0 1px $success-base;

        &:hover {
          background: $success-hover;
        }

        svg {
          filter: drop-shadow(0 1px 1px hsl(0 0% 0% / 0.1));
        }
      }

      &:focus-visible {
        outline: none;
        &::before {
          box-shadow:
            0 0 0 2px white,
            0 0 0 4px $success-base;
          opacity: 1;
        }
      }
    }

    &.reject-btn {
      &:hover:not(.active) {
        background: $error-light;
        border-color: $error-base;
        color: $error-base;
        transform: translateY(-1px);
        box-shadow: $shadow-sm;

        svg {
          transform: scale(1.15) rotate(90deg);
        }
      }

      &.active {
        background: $error-base;
        border-color: $error-base;
        color: white;
        box-shadow: 0 0 0 1px $error-base;

        &:hover {
          background: $error-hover;
        }

        svg {
          filter: drop-shadow(0 1px 1px hsl(0 0% 0% / 0.1));
        }
      }

      &:focus-visible {
        outline: none;
        &::before {
          box-shadow:
            0 0 0 2px white,
            0 0 0 4px $error-base;
          opacity: 1;
        }
      }
    }

    &:active {
      transform: translateY(0);
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
}

// Animations for approval/rejection
@keyframes approve-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes reject-animation {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1.15) rotate(180deg);
  }
  100% {
    transform: scale(1) rotate(360deg);
  }
}

.animate-approval {
  animation: approve-animation 0.4s $transition-bounce;
}

.animate-rejection {
  animation: reject-animation 0.4s $transition-bounce;
}

// Status badges to match the new style
.status-badge {
  display: inline-flex;
  align-items: center;
  gap: 0.375rem;
  height: 1.5rem;
  padding: 0 0.625rem;
  border-radius: 0.375rem;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: -0.01em;
  transition: $transition-smooth;
  white-space: nowrap;

  svg {
    width: 1rem;
    height: 1rem;
    stroke-width: 2.5;
  }

  &.approved {
    background: $success-light;
    color: $success-base;
  }

  &.rejected {
    background: $error-light;
    color: $error-base;
  }
}

// Question card animations
@keyframes card-approval {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-4px);
    box-shadow:
      0 8px 16px hsl(142.1 76.2% 36.3% / 0.1),
      0 16px 32px hsl(142.1 76.2% 36.3% / 0.1);
  }
  100% {
    transform: translateY(-2px);
    box-shadow:
      0 4px 8px hsl(142.1 76.2% 36.3% / 0.1),
      0 8px 16px hsl(142.1 76.2% 36.3% / 0.1);
  }
}

@keyframes card-rejection {
  0% {
    transform: translateY(0) rotate(0deg);
  }
  25% {
    transform: translateY(-2px) rotate(-1deg);
  }
  75% {
    transform: translateY(-4px) rotate(1deg);
  }
  100% {
    transform: translateY(-2px) rotate(0deg);
  }
}

.animate-approval-card {
  animation: card-approval 0.6s $transition-bounce forwards;
}

.animate-rejection-card {
  animation: card-rejection 0.6s $transition-bounce forwards;
}

// ... rest of your existing styles ...

.subquestion {
  // ... existing styles ...

  .subquestion-header {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.875rem 1rem;
    background: hsl(210 40% 98%);
    border-bottom: 1px solid hsl(214.3 31.8% 91.4%);

    .letter-badge {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 3.5rem;
      height: 1.75rem;
      padding: 0 0.625rem;
      background: hsl(221.2 83.2% 53.3%);
      color: white;
      border-radius: 0.375rem;
      font-weight: 600;
      font-size: 0.8125rem;
      letter-spacing: -0.01em;
      box-shadow:
        0 0 0 1px hsl(221.2 83.2% 53.3%),
        0 1px 2px hsl(221.2 83.2% 53.3% / 0.1);
    }

    .type-badge {
      padding: 0.25rem 0.625rem;
      background: hsl(214.3 31.8% 91.4% / 0.3);
      color: hsl(215.4 16.3% 46.9%);
      border-radius: 1rem;
      font-size: 0.75rem;
      font-weight: 500;
      letter-spacing: -0.01em;
    }

    .sub-approval-actions {
      display: flex;
      align-items: center;
      gap: 0.375rem;
      margin-left: auto;

      button {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: 2rem;
        padding: 0;
        background: white;
        border: 1px solid hsl(214.3 31.8% 91.4%);
        border-radius: 0.375rem;
        color: hsl(215.4 16.3% 46.9%);
        transition: $transition-smooth;
        cursor: pointer;

        &::before {
          content: '';
          position: absolute;
          inset: -2px;
          border-radius: 0.5rem;
          transition: $transition-fast;
          opacity: 0;
          pointer-events: none;
        }

        svg {
          width: 1rem;
          height: 1rem;
          stroke-width: 2;
          transition: $transition-bounce;
        }

        &.approve-btn {
          &:hover:not(.active) {
            background: $success-light;
            border-color: $success-base;
            color: $success-base;
            transform: translateY(-1px);

            svg {
              transform: scale(1.15);
            }
          }

          &.active {
            background: $success-base;
            border-color: $success-base;
            color: white;

            &:hover {
              background: $success-hover;
            }
          }

          &:focus-visible {
            outline: none;
            &::before {
              box-shadow:
                0 0 0 2px white,
                0 0 0 4px $success-base;
              opacity: 1;
            }
          }
        }

        &.reject-btn {
          &:hover:not(.active) {
            background: $error-light;
            border-color: $error-base;
            color: $error-base;
            transform: translateY(-1px);

            svg {
              transform: scale(1.15) rotate(90deg);
            }
          }

          &.active {
            background: $error-base;
            border-color: $error-base;
            color: white;

            &:hover {
              background: $error-hover;
            }
          }

          &:focus-visible {
            outline: none;
            &::before {
              box-shadow:
                0 0 0 2px white,
                0 0 0 4px $error-base;
              opacity: 1;
            }
          }
        }

        &:active {
          transform: translateY(0);
        }
      }
    }
  }

  &.approved {
    background: linear-gradient(135deg, hsl(142.1 76.2% 97% / 0.95), hsl(142.1 76.2% 98% / 0.98));
    border-color: hsl(142.1 76.2% 36.3% / 0.15);

    .subquestion-header {
      background: linear-gradient(
        to right,
        hsl(142.1 76.2% 36.3% / 0.08),
        hsl(142.1 76.2% 36.3% / 0.04)
      );
      border-bottom-color: hsl(142.1 76.2% 36.3% / 0.1);
    }
  }

  &.rejected {
    background: linear-gradient(135deg, hsl(0 84.2% 97% / 0.95), hsl(0 84.2% 98% / 0.98));
    border-color: hsl(0 84.2% 60.2% / 0.15);

    .subquestion-header {
      background: linear-gradient(to right, hsl(0 84.2% 60.2% / 0.08), hsl(0 84.2% 60.2% / 0.04));
      border-bottom-color: hsl(0 84.2% 60.2% / 0.1);
    }
  }
}

// ... rest of your existing styles ...

.step {
  position: relative;
  opacity: 0;
  height: 0;
  overflow: hidden;
  transform: translateY(10px);
  transition: all 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);

  &.active {
    opacity: 1;
    height: auto;
    transform: translateY(0);
    overflow: visible;
  }

  .step-content {
    display: flex;
    align-items: center;
    gap: 1.25rem;
    padding: 1.5rem;
    background: white;
    border-radius: 1rem;
    border: 1px solid rgba(228, 177, 122, 0.15);
    box-shadow:
      0 4px 24px -6px rgba(228, 177, 122, 0.06),
      0 12px 40px -8px rgba(228, 177, 122, 0.04);
  }
}

.processing-steps {
  position: relative;
  width: 100%;
  max-width: 32rem;
  padding: 0 2rem;
}

.upload-section {
  text-align: center;
  padding: 2rem;
  // background: linear-gradient(135deg, #fdf8f3 0%, #f9ede2 100%);
  // border-radius: 1rem;
  // border: 1px solid rgba(228, 177, 122, 0.2);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    border-color: rgba(228, 177, 122, 0.4);
    // box-shadow:
    //   0 4px 24px -6px rgba(228, 177, 122, 0.1),
    //   0 12px 40px -8px rgba(228, 177, 122, 0.08);
  }

  .upload-icon {
    width: 3rem;
    height: 3rem;
    color: #e4b17a;
    margin-bottom: 1.5rem;
    filter: drop-shadow(0 4px 12px rgba(228, 177, 122, 0.2));
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 600;
    color: #1a1a1a;
    margin-bottom: 0.75rem;
  }

  p {
    font-size: 0.875rem;
    color: #4a4a4a;
    margin-bottom: 1.5rem;
  }

  .upload-button {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.5rem;
    background: linear-gradient(135deg, #e4b17a 0%, #c99b69 100%);
    color: white;
    border: none;
    border-radius: 0.75rem;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background: linear-gradient(135deg, #c99b69 0%, #b38a5e 100%);
      transform: translateY(-1px);
      box-shadow: 0 4px 12px rgba(228, 177, 122, 0.2);
    }

    &:active {
      transform: translateY(0);
    }
  }
}

.solution-section {
  margin-top: 2.5rem;
  background: #ffffff;
  border-radius: 1.5rem;
  border: 1px solid rgba(228, 177, 122, 0.15);
  box-shadow:
    0 4px 12px -2px rgba(228, 177, 122, 0.08),
    0 8px 16px -4px rgba(228, 177, 122, 0.06);
  position: relative;
  isolation: isolate;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
      135deg,
      rgba(228, 177, 122, 0.03) 0%,
      rgba(255, 255, 255, 0) 50%,
      rgba(228, 177, 122, 0.03) 100%
    );
    opacity: 0;
    transition: opacity 0.4s ease;
    z-index: -1;
  }

  &:hover {
    transform: translateY(-2px);
    border-color: rgba(228, 177, 122, 0.25);
    box-shadow:
      0 20px 40px -12px rgba(228, 177, 122, 0.12),
      0 8px 16px -4px rgba(228, 177, 122, 0.08);

    &::before {
      opacity: 1;
    }

    .solution-header {
      background: linear-gradient(to right, rgba(253, 248, 243, 0.9), rgba(255, 255, 255, 0.9));

      h3 svg {
        transform: scale(1.05) rotate(5deg);
        filter: drop-shadow(0 4px 6px rgba(228, 177, 122, 0.3));
      }
    }
  }

  .solution-header {
    padding: 2rem 2.5rem;
    background: linear-gradient(to right, rgba(253, 248, 243, 0.8), rgba(255, 255, 255, 0.8));
    backdrop-filter: blur(12px);
    border-bottom: 1px solid rgba(228, 177, 122, 0.12);
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 1px;
      background: linear-gradient(to right, transparent, rgba(228, 177, 122, 0.25), transparent);
    }

    h3 {
      display: flex;
      align-items: center;
      gap: 0.875rem;
      font-size: 1.5rem;
      font-weight: 600;
      color: #e4b17a;
      margin-bottom: 0.75rem;
      letter-spacing: -0.02em;

      svg {
        color: #e4b17a;
        filter: drop-shadow(0 2px 4px rgba(228, 177, 122, 0.25));
        transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
      }
    }

    .solution-description {
      font-size: 1rem;
      color: #c99b69;
      letter-spacing: -0.01em;
      line-height: 1.6;
      opacity: 0.9;
    }
  }

  .solution-content {
    padding: 2.5rem;

    .solution-textarea {
      width: 100%;
      min-height: 240px;
      padding: 1.75rem;
      background: linear-gradient(
        135deg,
        rgba(253, 248, 243, 0.5) 0%,
        rgba(255, 255, 255, 0.5) 100%
      );
      border: 1px solid rgba(228, 177, 122, 0.2);
      border-radius: 1.25rem;
      font-family: 'JetBrains Mono', 'Fira Code', 'Monaco', monospace;
      font-size: 0.9375rem;
      line-height: 1.8;
      color: #8c6b4a;
      resize: vertical;
      transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);

      &:focus {
        outline: none;
        border-color: #e4b17a;
        background: white;
        box-shadow:
          0 0 0 3px rgba(228, 177, 122, 0.1),
          0 8px 24px -4px rgba(228, 177, 122, 0.12);
      }

      &::placeholder {
        color: #c99b69;
        opacity: 0.6;
      }
    }

    .solution-text {
      color: #8c6b4a;
      line-height: 1.85;
      font-size: 1.0625rem;
      letter-spacing: -0.01em;

      .solution-paragraph {
        margin-bottom: 2.75rem;
        padding: 0.5rem 0;
        opacity: 0.95;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .math-block {
        margin: 2.5rem 0;
        padding: 2.5rem;
        background: linear-gradient(
          135deg,
          rgba(253, 248, 243, 0.9) 0%,
          rgba(255, 255, 255, 0.9) 100%
        );
        border-radius: 1.25rem;
        border: 1px solid rgba(228, 177, 122, 0.15);
        overflow-x: auto;
        position: relative;
        transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);

        &::before {
          content: '';
          position: absolute;
          inset: 0;
          border-radius: inherit;
          padding: 1px;
          background: linear-gradient(135deg, rgba(228, 177, 122, 0.25), rgba(228, 177, 122, 0.15));
          mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
          -webkit-mask-composite: xor;
          mask-composite: exclude;
          pointer-events: none;
        }

        &:hover {
          transform: scale(1.01) translateY(-2px);
          border-color: rgba(228, 177, 122, 0.25);
          box-shadow:
            0 20px 40px -12px rgba(228, 177, 122, 0.12),
            0 8px 16px -4px rgba(228, 177, 122, 0.08);
        }

        .katex-display {
          margin: 0;
          opacity: 0.95;
        }

        &::-webkit-scrollbar {
          height: 8px;
        }

        &::-webkit-scrollbar-track {
          background: rgba(228, 177, 122, 0.05);
          border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb {
          background: rgba(228, 177, 122, 0.2);
          border-radius: 4px;

          &:hover {
            background: rgba(228, 177, 122, 0.3);
          }
        }
      }

      .katex {
        font-size: 1.15em;
        color: #8c6b4a;
      }

      .katex-html {
        .mord,
        .mbin,
        .mrel,
        .mopen,
        .mclose,
        .mpunct {
          color: #8c6b4a;
        }

        .mop,
        .minner {
          color: #c99b69;
        }

        .mfrac {
          .frac-line {
            border-color: #e4b17a;
          }
        }

        .sqrt {
          .sqrt-sign {
            color: #e4b17a;
          }
        }

        .accent {
          color: #e4b17a;
        }
      }
    }
  }

  @keyframes solutionFadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  animation: solutionFadeIn 0.6s cubic-bezier(0.19, 1, 0.22, 1) forwards;
}

// Add these new styles after your existing .upload-section styles

.back-button-container {
  display: flex;
  padding: 1.5rem 2rem;
  background: white;
  border-bottom: 1px solid #e2e8f0;
  width: 100%;
  z-index: 10;

  .back-button {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background: white;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: #64748b;
    transition: all 0.2s ease;
    cursor: pointer;

    &:hover {
      background: #f8fafc;
      color: #334155;
      border-color: #cbd5e1;
    }
  }
}

.upload-boxes-container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;

  .upload-box {
    flex: 1;
    max-width: 400px;
    min-height: 320px;
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.02);
    border: 2px dashed #e2e8f0;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    padding: 2rem;

    &:hover {
      transform: translateY(-2px);
      border-color: #e4b17a;
      background: #fdf8f3;

      .upload-icon {
        transform: translateY(-4px);
        color: #e4b17a;
      }
    }

    .upload-label {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      cursor: pointer;

      .upload-icon {
        width: 48px;
        height: 48px;
        color: #e4b17a;
        margin-bottom: 1.5rem;
        transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
      }

      span {
        font-size: 1.25rem;
        font-weight: 500;
        color: #e4b17a;
        // margin-bottom: 1rem;
      }

      p {
        font-size: 0.875rem;
        color: #64748b;
        margin-bottom: 2rem;
      }

      .upload-tip {
        padding: 0.75rem 1.25rem;
        background: rgba(228, 177, 122, 0.1);
        border-radius: 0.75rem;
        font-size: 0.875rem;
        color: #e4b17a;
        width: max-content;
        // max-width: 200px;
      }
    }

    &.hover {
      border-color: #e4b17a;
      background: #fdf8f3;
    }

    &.uploaded {
      animation: successBackground 0.5s cubic-bezier(0.4, 0, 0.2, 1) forwards;
      border-color: #e4b17a;
      border-style: solid;

      .upload-label {
        position: relative;

        // Fade out original content
        .upload-icon,
        > span,
        p,
        .upload-tip {
          animation: fadeOutContent 0.3s forwards;
        }

        // Success overlay
        .success-overlay {
          position: absolute;
          inset: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          animation: fadeInSuccess 0.5s 0.3s forwards;
          opacity: 0;

          .success-checkmark {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            background: rgba(228, 177, 122, 0.1);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 1.5rem;
            position: relative;

            &::before {
              content: '';
              width: 40px;
              height: 80px;
              background: rgba(228, 177, 122, 0.15);
              position: absolute;
              right: 0;
              border-radius: 0 40px 40px 0;
            }

            svg {
              width: 40px;
              height: 40px;
              color: #e4b17a;
              animation: successCheckmark 0.6s 0.4s cubic-bezier(0.23, 1, 0.32, 1) forwards;
              opacity: 0;
              transform-origin: center;
            }
          }

          .success-text {
            font-size: 1.25rem;
            font-weight: 500;
            color: #e4b17a;
            margin-bottom: 0.5rem;
          }

          .success-subtext {
            font-size: 0.875rem;
            color: #c99b69;
          }
        }
      }
    }
  }

  // Add responsive behavior
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 1rem;

    .upload-box {
      width: 100%;
      max-width: 100%;
    }
  }
}

@keyframes successCheckmark {
  0% {
    transform: scale(0) rotate(-45deg);
    opacity: 0;
  }
  50% {
    transform: scale(1.2) rotate(-45deg);
  }
  100% {
    transform: scale(1) rotate(-45deg);
    opacity: 1;
  }
}

@keyframes successBackground {
  0% {
    transform: scale(0.8);
    background: white;
  }
  100% {
    transform: scale(1);
    background: rgba(228, 177, 122, 0.05);
  }
}

@keyframes fadeOutContent {
  to {
    opacity: 0;
    transform: translateY(10px);
  }
}

@keyframes fadeInSuccess {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.proceed-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  gap: 1rem;
  
  .button-spinner {
    width: 20px;
    height: 20px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 0.8s linear infinite;
    margin-right: 0.5rem;
  }

  .proceed-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    font-weight: 600;
    transition: all 0.3s ease;

    &.enabled {
      background-color: #e4b17a;
      color: white;
      cursor: pointer;

      &:hover {
        background-color: darken(#e4b17a, 10%);
        transform: translateY(-2px);
      }
    }

    &.disabled {
      background-color: #e5e7eb;
      color: #9ca3af;
      cursor: not-allowed;
    }
  }

  .proceed-hint {
    color: #6b7280;
    font-size: 0.875rem;
    text-align: center;
    animation: fadeIn 0.3s ease;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
