// Variables
$transition-smooth: cubic-bezier(0.4, 0, 0.2, 1);
$transition-bounce: cubic-bezier(0.34, 1.56, 0.64, 1);
$primary-gradient: linear-gradient(135deg, #e4b17a, #c99b69);
$success-gradient: linear-gradient(135deg, #e4b17a, #c99b69);
$error-gradient: linear-gradient(135deg, hsl(0 84.2% 60.2%), hsl(0 84.2% 50.2%));

// Colors
$primary-color: #e4b17a;
$primary-dark: #c99b69;
$text-primary: #8c6b4a;
$text-secondary: #a17c57;
$bg-light: #fdf8f3;
$bg-medium: #f9ede2;
$bg-white: #ffffff;

// Mixins
@mixin glass-effect {
  backdrop-filter: blur(12px) saturate(180%);
  -webkit-backdrop-filter: blur(12px) saturate(180%);
}

@mixin hover-lift {
  transition: all 0.3s $transition-bounce;
  &:hover {
    transform: translateY(-2px);
  }
}

@mixin card-shadow {
  box-shadow: rgba(228, 177, 122, 0.06) 0px 2px 4px -2px,
              rgba(228, 177, 122, 0.04) 0px 4px 8px -2px;
}

@mixin card-shadow-hover {
  box-shadow: rgba(228, 177, 122, 0.08) 0px 12px 24px -6px,
              rgba(228, 177, 122, 0.06) 0px 18px 36px -12px;
}

// body {
//   background: #fdf8f3;
//   color: #8c6b4a;
// }

.main-container {
  background: linear-gradient(135deg, $bg-light 0%, $bg-medium 100%);

  .header {
    background: $bg-white;
    border-bottom: 1px solid rgba(228, 177, 121, 0.2);

    h1 {
      color: $text-primary;
    }

    p {
      color: $text-secondary;
    }
  }

  .content {
    background: $bg-white;

    .section-title {
      color: $text-primary;
    }

    .section-description {
      color: $text-secondary;
    }
  

  .button {
    background: linear-gradient(135deg, #e4b17a, #c99b69);
    color: white;

      &:hover {
        background: linear-gradient(135deg, #c99b69, #b38a5e);
      }
    }

    .link {
      color: #e4b17a;

      &:hover {
        color: #c99b69;
      }
    }
  }

  .card {
    background: white;
    border: 1px solid rgba(228, 177, 121, 0.2);
    box-shadow: 0 4px 6px -1px rgba(228, 177, 121, 0.05),
    0 2px 4px -1px rgba(228, 177, 121, 0.03);

    &:hover {
      border-color: #e4b17a;
      box-shadow: 0 6px 8px -1px rgba(228, 177, 121, 0.1),
      0 4px 6px -1px rgba(228, 177, 121, 0.06);
    }
  }

  input, textarea, select {
    border: 1px solid rgba(228, 177, 121, 0.2);
    color: #8c6b4a;

    &::placeholder {
      color: #c99b69;
    }

    &:focus {
      border-color: #e4b17a;
      box-shadow: 0 0 0 3px rgba(228, 177, 121, 0.1);
    }
  }

  .icon {
    color: #e4b17a;

    &:hover {
      color: #c99b69;
    }
  }
}

// Download step styling to match RubricViewer
.download-step {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;

  .content-container {
    width: 100%;
    max-width: 800px;
  }

  .marker-download-card {
    background: $bg-white;
    border-radius: 1rem;
    padding: 2rem;
    border: 1px solid rgba($primary-color, 0.12);
    @include card-shadow;
    transition: all 0.3s $transition-smooth;

    &:hover {
      @include card-shadow-hover;
      border-color: rgba($primary-color, 0.2);
    }

    .section-title {
      font-size: 1.5rem;
      font-weight: 600;
      color: $text-primary;
      margin-bottom: 1rem;
    }

    .section-title {
      font-size: 1.5rem;
      font-weight: 700;
      color: darken($text-primary, 15%);
      margin-bottom: 1rem;
      letter-spacing: -0.01em;
    }

    .section-description {
      color: darken($text-secondary, 20%);
      margin-bottom: 1.5rem;
      line-height: 1.6;
      font-weight: 500;
      font-size: 1.05rem;
    }

    .info-notice {
      background: rgba($primary-color, 0.05);
      border-left: 4px solid $primary-color;
      padding: 1rem;
      margin-bottom: 1.5rem;
      border-radius: 0 0.5rem 0.5rem 0;
      
      .notice-content {
        color: darken($text-primary, 15%);
        font-size: 0.95rem;
        line-height: 1.5;
        font-weight: 500;
      }
    }

    .success-notice {
      background: rgba(#4CAF50, 0.05);
      border-left: 4px solid #4CAF50;
      padding: 1rem;
      margin-top: 1.5rem;
      border-radius: 0 0.5rem 0.5rem 0;
      display: flex;
      align-items: center;
      
      .notice-content {
        color: #2E7D32;
        font-size: 0.9rem;
        line-height: 1.5;
        display: flex;
        align-items: center;
        
        .icon {
          margin-right: 0.5rem;
          width: 18px;
          height: 18px;
        }
      }
    }

    .action-buttons {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 1.5rem;
      gap: 1.5rem;
      width: 100%;
      position: relative;

      .marker-download-button.download-button, .continue-button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        padding: 0.75rem 1.25rem;
        border-radius: 0.5rem !important;
        font-weight: 600;
        transition: all 0.3s $transition-bounce;
        border: none;
        cursor: pointer;
        font-size: 0.95rem;
        @include hover-lift;
        
        .icon {
          width: 18px;
          height: 18px;
        }
      }

      // Override the global .download-button class with more specific selector
      .marker-download-button.download-button {
        background: $primary-gradient;
        color: white;
        position: static !important;
        display: inline-flex !important;
        border-radius: 0.5rem !important;
        top: auto !important;
        right: auto !important;
        margin: 0 !important;
        z-index: 1 !important;

        &:hover {
          transform: translateY(-2px);
          filter: brightness(1.05);
        }
        
        &.loading {
          opacity: 0.8;
          cursor: not-allowed;
          
          .spinner {
            width: 18px;
            height: 18px;
            border: 2px solid rgba(255,255,255,0.3);
            border-radius: 50%;
            border-top-color: white;
            animation: spin 1s ease-in-out infinite;
          }
        }
      }

      .continue-button {
        background: rgba($primary-color, 0.1);
        color: darken($primary-dark, 10%);
        
        &:hover {
          background: rgba($primary-color, 0.15);
        }
        
        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
          background: #e0e0e0;
          color: #757575;
          transform: none;
        }
      }
    }
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.solution-rubrics {
  .content-area {
    flex: 1;
    background: linear-gradient(135deg, #f8fafc, #f1f5f9);
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 0;
    padding: 0;
  }

  .page-header {
    position: relative;
    background: linear-gradient(180deg, #ffffff, #f8fafc);
    border-bottom: 1px solid rgba(226, 232, 240, 0.8);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    .step-indicator {
      position: absolute;
      top: 1rem;
      right: 2rem;
      background: $primary-color;
      color: white;
      font-size: 1.05rem; /* Increased by 40% from 0.75rem */
      font-weight: 600;
      padding: 0.25rem 0.5rem;
      border-radius: 0.375rem;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .header-left {
      display: flex;
      align-items: center;
      gap: 1.5rem;

      .back-button {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem 0.75rem;
        border-radius: 0.375rem;
        background-color: rgba($primary-color, 0.1);
        color: $text-primary;
        text-decoration: none;
        font-weight: 500;
        transition: all 0.2s ease;
        
        &:hover {
          background-color: rgba($primary-color, 0.2);
        }
      }

      .gradewiz-logo-container {
        display: flex;
        align-items: center;
        gap: 1rem;
        position: relative;

        .gradewiz-logo {
          height: 2.5rem;
          width: auto;
        }

        h1 {
          background: linear-gradient(135deg, #1e293b, #334155);
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 1.75rem;
          letter-spacing: -0.03em;
          margin: 0;
        }


      }
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      height: 1px;
      background: linear-gradient(90deg,
              rgba(226, 232, 240, 0) 0%,
              rgba(226, 232, 240, 1) 15%,
              rgba(226, 232, 240, 1) 85%,
              rgba(226, 232, 240, 0) 100%
      );
    }
  }

  .upload-section {
    // max-width: 600px;
    width: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    animation: fadeIn 0.5s ease;

    label {
      font-size: 1.25rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }

    // Variables
    .sleek-toggle {
      --toggle-width: 280px;
      --toggle-height: 56px;
      --thumb-size: 48px;
      --student-color: #4a90e2;
      --teacher-color: #e4b17a;
      --student-gradient: linear-gradient(135deg, #3a7bd5, #4a90e2);
      --teacher-gradient: linear-gradient(135deg, #e4b17a, #c99b69);
      --background: #ffffff;
      --shadow-sm: 0 2px 8px rgba(0, 0, 0, 0.06);
      --shadow-md: 0 4px 12px rgba(0, 0, 0, 0.08);
      --shadow-lg: 0 8px 24px rgba(0, 0, 0, 0.12);

      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.25rem;
      padding: 1.5rem;

      .toggle-title {
        font-size: 1.35rem;
        font-weight: 700;
        color: #1a2b3c;
        letter-spacing: -0.02em;
        margin: 0;
        text-align: center;
      }

      .toggle-container {
        position: relative;
        padding: 4px;
        background: var(--background);
        border-radius: calc(var(--toggle-height) / 2 + 4px);
        box-shadow: var(--shadow-sm);
      }

      .toggle-switch {
        position: relative;
        display: block;
        width: var(--toggle-width);
        height: var(--toggle-height);

        input {
          position: absolute;
          opacity: 0;
          width: 0;
          height: 0;
        }

        .switch-track {
          position: relative;
          width: 100%;
          height: 100%;
          border-radius: calc(var(--toggle-height) / 2);
          background: #f8fafc;
          cursor: pointer;
          transition: all 0.4s cubic-bezier(0.4, 0.0, 0.2, 1);
          overflow: hidden;
        }

        .switch-background {
          position: absolute;
          inset: 0;
          opacity: 0.08;
          transition: opacity 0.3s ease;
        }

        &.student-mode .switch-background {
          background: var(--student-gradient);
        }

        &.teacher-mode .switch-background {
          background: var(--teacher-gradient);
        }

        .labels-container {
          position: absolute;
          inset: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 4px;
          z-index: 2;
        }

        .option-wrapper {
          position: relative;
          flex: 1;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: calc((var(--toggle-height) - 8px) / 2);
          transition: all 0.3s cubic-bezier(0.4, 0.0, 0.2, 1);

          .option-text {
            font-size: 0.95rem;
            font-weight: 600;
            color: #64748b;
            transition: all 0.3s ease;
            z-index: 2;
          }

          &.active {
            .option-text {
              color: #1a2b3c;
            }
          }
        }

        .switch-thumb {
          position: absolute;
          top: 4px;
          left: 4px;
          width: calc(var(--toggle-width) / 2 - 8px);
          height: calc(var(--toggle-height) - 8px);
          transition: transform 0.4s cubic-bezier(0.4, 0.0, 0.2, 1);
          z-index: 1;

          .thumb-content {
            width: 100%;
            height: 100%;
            background: var(--background);
            border-radius: calc((var(--toggle-height) - 8px) / 2);
            box-shadow: var(--shadow-sm);
            transition: all 0.3s ease;
          }
        }

        input:checked + .switch-track .switch-thumb {
          transform: translateX(calc(var(--toggle-width) / 2));
        }

        &:hover {
          .switch-thumb .thumb-content {
            box-shadow: var(--shadow-md);
          }
        }

        &:active {
          .switch-thumb .thumb-content {
            box-shadow: var(--shadow-lg);
          }
        }

        &.student-mode.active {
          .switch-thumb .thumb-content {
            background: var(--student-gradient);
          }
        }

        &.teacher-mode.active {
          .switch-thumb .thumb-content {
            background: var(--teacher-gradient);
          }
        }
      }
    }

  }

  .upload-box {
    animation: fadeIn 0.5s ease;
    width: 100%;
    min-height: 320px;
    border: 2px dashed #e2e8f0;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    background: white;
    padding: 2rem;

    &:hover {
      border-color: #e4b17a;
      background: #fdf8f3;
      transform: translateY(-1px);
      box-shadow: 0 4px 12px -2px rgba(228, 177, 122, 0.1);
    }

    &.hover {
      border-color: #e4b17a;
      background: linear-gradient(135deg, #fdf8f3, #fefcf9);
      transform: translateY(-4px);
      box-shadow: 0 12px 48px rgba(228, 177, 122, 0.1),
      0 24px 64px rgba(228, 177, 122, 0.05);

      .upload-icon {
        transform: translateY(-8px) scale(1.1);
        color: #e4b17a;
      }
    }
  }

  .rubric-viewer {
    height: 100%;
    width: 100%;
    background: white;
    border-radius: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    animation: fadeIn 0.3s ease;
    min-height: 0;
    box-shadow: none;

    .viewer-header {

      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 1.5rem 0;
      background: white;
      border-bottom: 1px solid #e2e8f0;
      padding-top: 0px;

      .viewer-tabs {
        display: flex;
        gap: 1rem;
      }

      .change-document-btn {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem 1rem;
        background: white;
        border: 1px solid #e2e8f0;
        border-radius: 0.5rem;
        color: #64748b;
        font-size: 0.875rem;
        font-weight: 500;
        transition: all 0.2s;

        &:hover {
          background: #f8fafc;
          border-color: #cbd5e1;
          color: #475569;
        }
      }
    }

    .tab {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.75rem 1.5rem;
      background: transparent;
      border: none;
      color: #64748b;
      font-size: 0.875rem;
      font-weight: 500;
      cursor: pointer;
      border-bottom: 2px solid transparent;
      transition: all 0.2s ease;

      &:hover:not(:disabled) {
        color: #e4b17a;
      }

      &.active {
        color: #e4b17a;
        border-bottom-color: #e4b17a;
        font-weight: 600;
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      .question-count {
        margin-left: 0.5rem;
        padding: 0.25rem 0.5rem;
        background: rgba(228, 177, 122, 0.1);
        border-radius: 1rem;
        font-size: 0.75rem;
        color: #e4b17a;
        font-weight: 600;
      }
    }

    .viewer-content {
      flex: 1;
      overflow: hidden;
      position: relative;
      min-height: 0;
      display: flex;

      .document-preview {
        flex: 1;
        display: flex;
        background: white;

        object {
          flex: 1;
          border: none;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .questions-view {
    height: 100%;
    overflow-y: auto;
    padding: 1rem;
    width: 100%;
    background: linear-gradient(135deg,
            hsl(0 0% 100% / 0.98),
            hsl(210 40% 98% / 0.97)
    );
    @include glass-effect;

    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: hsl(215.4 16.3% 46.9% / 0.1);
      border-radius: 100px;
      transition: all 0.3s $transition-smooth;

      &:hover {
        background: hsl(215.4 16.3% 46.9% / 0.2);
      }
    }

    .questions-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(min(100%, 520px), 1fr));
      gap: 1.5rem;
      max-width: 1400px;
      margin: 0 auto;
      padding: 0.5rem;

      @media (min-width: 1200px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
      }
    }

    .question-card {
      position: relative;
      background: white;
      border-radius: 1rem;
      box-shadow: 0 1px 2px hsl(0 0% 0% / 0.02),
      0 2px 4px hsl(0 0% 0% / 0.02),
      0 4px 8px hsl(0 0% 0% / 0.02);
      transition: all 0.4s $transition-bounce;
      opacity: 0;
      animation: fadeInScale 0.5s $transition-bounce forwards;
      animation-delay: var(--animation-delay);
      border: 1px solid hsl(214.3 31.8% 91.4%);
      overflow: hidden;
      transform-style: preserve-3d;
      will-change: transform, box-shadow;

      &:hover {
        transform: translateY(-2px) translateZ(2px);
        box-shadow: 0 4px 12px hsl(0 0% 0% / 0.03),
        0 12px 24px hsl(0 0% 0% / 0.03),
        0 -1px 2px hsl(0 0% 0% / 0.02);
      }

      &.approved {
        background: linear-gradient(135deg,
                hsl(142.1 76.2% 97% / 0.95),
                hsl(142.1 76.2% 98% / 0.98)
        );
        border-color: hsl(142.1 76.2% 36.3% / 0.15);

        .question-header {
          background: linear-gradient(to right,
                  hsl(142.1 76.2% 36.3% / 0.08),
                  hsl(142.1 76.2% 36.3% / 0.04)
          );
          border-bottom-color: hsl(142.1 76.2% 36.3% / 0.1);
        }
      }

      &.rejected {
        background: linear-gradient(135deg,
                hsl(0 84.2% 97% / 0.95),
                hsl(0 84.2% 98% / 0.98)
        );
        border-color: hsl(0 84.2% 60.2% / 0.15);

        .question-header {
          background: linear-gradient(to right,
                  hsl(0 84.2% 60.2% / 0.08),
                  hsl(0 84.2% 60.2% / 0.04)
          );
          border-bottom-color: hsl(0 84.2% 60.2% / 0.1);
        }
      }

      .question-header {
        padding: 1.25rem;
        background: linear-gradient(to right,
                hsl(210 40% 98% / 0.8),
                hsl(210 40% 98% / 0.6)
        );
        border-bottom: 1px solid hsl(214.3 31.8% 91.4% / 0.7);
        @include glass-effect;

        .header-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 1rem;
        }

        h3 {
          display: flex;
          align-items: center;
          gap: 0.75rem;
          margin: 0;
          font-size: 0.9375rem;
          font-weight: 500;
          color: hsl(222.2 47.4% 11.2%);

          .question-number {
            background: $primary-gradient;
            color: white;
            padding: 0.375rem 0.75rem;
            border-radius: 0.5rem;
            font-size: 0.8125rem;
            font-weight: 500;
            letter-spacing: -0.01em;
            box-shadow: 0 1px 2px hsl(221.2 83.2% 53.3% / 0.1),
            0 2px 4px hsl(221.2 83.2% 53.3% / 0.1);
          }
        }

        .status-badge {
          display: inline-flex;
          align-items: center;
          gap: 0.375rem;
          padding: 0.25rem 0.625rem;
          border-radius: 0.375rem;
          font-size: 0.75rem;
          font-weight: 500;
          opacity: 0;
          transform: translateX(-10px);
          animation: slideIn 0.3s $transition-bounce forwards;
          letter-spacing: -0.01em;

          &.approved {
            background: rgba(228, 177, 122, 0.1);
            color: #e4b17a;
            border: 1px solid rgba(228, 177, 122, 0.2);
          }

          &.rejected {
            background: hsl(0 84.2% 60.2% / 0.1);
            color: hsl(0 84.2% 60.2%);
            border: 1px solid hsl(0 84.2% 60.2% / 0.2);
          }

          svg {
            stroke-width: 2.5;
            width: 12px;
            height: 12px;
          }
        }
      }

      .question-content {
        padding: 1.5rem;

        .question-text-wrapper {
          display: flex;
          gap: 0.875rem;
          align-items: flex-start;
          margin-bottom: 1.25rem;
          background: hsl(210 40% 98%);
          padding: 1.25rem;
          border-radius: 0.875rem;
          border: 1px solid hsl(214.3 31.8% 91.4%);

          .question-icon {
            color: hsl(215.4 16.3% 46.9%);
            margin-top: 0.125rem;
            width: 16px;
            height: 16px;
          }

          .question-text {
            margin: 0;
            color: hsl(222.2 47.4% 11.2%);
            line-height: 1.7;
            font-size: 0.9375rem;
            letter-spacing: -0.01em;
            font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;

            // LaTeX styles
            .katex {
              font-size: 1.1em;
              margin: 0 0.1em;
              vertical-align: -0.1em;
            }

            .katex-display {
              margin: 1em 0;
              overflow-x: auto;
              overflow-y: hidden;
              padding: 0.5em 0;
            }
          }
        }
      }

      .subquestions {
        margin-top: 1.75rem;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        .subquestion {
          background: white;
          border-radius: 1rem;
          border: 1px solid hsl(214.3 31.8% 91.4%);
          overflow: hidden;
          transition: all 0.3s $transition-bounce;
          box-shadow: 0 1px 2px hsl(0 0% 0% / 0.02),
          0 2px 4px hsl(0 0% 0% / 0.02);

          &:hover {
            transform: translateY(-1px);
            box-shadow: 0 4px 8px hsl(0 0% 0% / 0.03),
            0 8px 16px hsl(0 0% 0% / 0.03);
          }

          .subquestion-header {
            display: flex;
            align-items: center;
            gap: 0.75rem;
            padding: 1rem;
            background: hsl(210 40% 98%);
            border-bottom: 1px solid hsl(214.3 31.8% 91.4%);

            .letter-badge {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 2rem;
              height: 2rem;
              background: $primary-gradient;
              color: white;
              border-radius: 0.5rem;
              font-weight: 600;
              font-size: 0.875rem;
              box-shadow: 0 2px 4px hsl(221.2 83.2% 53.3% / 0.1),
              0 4px 8px hsl(221.2 83.2% 53.3% / 0.1);
            }

            .type-badge {
              padding: 0.375rem 0.75rem;
              background: hsl(214.3 31.8% 91.4% / 0.3);
              color: hsl(215.4 16.3% 46.9%);
              border-radius: 2rem;
              font-size: 0.75rem;
              font-weight: 500;
              letter-spacing: -0.01em;
            }
          }

          .subquestion-content {
            padding: 1.25rem;

            .question-text {
              color: hsl(222.2 47.4% 11.2%);
              font-size: 0.9375rem;
              line-height: 1.7;
              letter-spacing: -0.01em;
              margin-bottom: 1.25rem;

              .katex {
                font-size: 1.05em;
                margin: 0 0.1em;
              }
            }
          }

          &.mcq {
            .options-list {
              display: flex;
              flex-direction: column;
              gap: 0.75rem;
              margin-top: 1.25rem;

              .option-item {
                display: flex;
                gap: 1rem;
                padding: 1rem;
                background: hsl(210 40% 98%);
                border: 1px solid hsl(214.3 31.8% 91.4%);
                border-radius: 0.75rem;
                transition: all 0.3s $transition-bounce;

                &:hover {
                  background: white;
                  transform: translateY(-1px);
                  box-shadow: 0 2px 4px hsl(0 0% 0% / 0.03),
                  0 4px 8px hsl(0 0% 0% / 0.03);
                }

                &.correct {
                  background: hsl(142.1 76.2% 36.3% / 0.05);
                  border-color: hsl(142.1 76.2% 36.3% / 0.2);

                  .option-letter {
                    background: hsl(142.1 76.2% 36.3%);
                    color: white;
                    border-color: hsl(142.1 76.2% 36.3%);
                  }

                  &:hover {
                    background: hsl(142.1 76.2% 36.3% / 0.08);
                  }
                }

                .option-letter {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 2rem;
                  height: 2rem;
                  background: white;
                  border: 1px solid hsl(214.3 31.8% 91.4%);
                  border-radius: 0.5rem;
                  color: hsl(215.4 16.3% 46.9%);
                  font-weight: 600;
                  font-size: 0.875rem;
                  flex-shrink: 0;
                }

                .option-content {
                  flex: 1;
                  display: flex;
                  flex-direction: column;
                  gap: 0.5rem;

                  .option-text {
                    color: hsl(222.2 47.4% 11.2%);
                    font-size: 0.9375rem;
                    line-height: 1.6;
                    letter-spacing: -0.01em;

                    .katex {
                      font-size: 1.05em;
                      margin: 0 0.1em;
                    }
                  }

                  .correct-indicator {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    color: hsl(142.1 76.2% 36.3%);
                    font-size: 0.8125rem;
                    font-weight: 500;

                    .correct-mark {
                      width: 1rem;
                      height: 1rem;
                      stroke-width: 2.5;
                    }
                  }
                }
              }
            }
          }

          &.freeform {
            .solution-wrapper {
              margin-top: 1.5rem;
              padding-top: 1.5rem;
              border-top: 1px dashed hsl(214.3 31.8% 91.4%);

              .solution {
                background: hsl(210 40% 98%);
                border-radius: 0.875rem;
                border: 1px solid hsl(214.3 31.8% 91.4%);
                overflow: hidden;

                .solution-header {
                  display: flex;
                  align-items: center;
                  gap: 0.5rem;
                  padding: 0.75rem 1rem;
                  background: white;
                  border-bottom: 1px solid hsl(214.3 31.8% 91.4%);
                  color: hsl(215.4 16.3% 46.9%);
                  font-weight: 500;
                  font-size: 0.875rem;

                  svg {
                    color: hsl(47.9 95.8% 53.1%);
                    width: 1rem;
                    height: 1rem;
                  }
                }

                .solution-content {
                  padding: 1rem;
                  color: hsl(215.4 16.3% 46.9%);
                  font-size: 0.9375rem;
                  line-height: 1.7;
                  letter-spacing: -0.01em;

                  .katex {
                    font-size: 1.05em;
                    margin: 0 0.1em;
                    color: hsl(222.2 47.4% 11.2%);
                  }

                  .katex-display {
                    margin: 1em 0;
                    padding: 0.5em 0;
                    overflow-x: auto;
                    overflow-y: hidden;
                  }
                }
              }
            }
          }
        }
      }

      .approval-actions {
        display: flex;
        gap: 0.75rem;
        perspective: 1000px;

        button {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 0.625rem;
          border: 1px solid hsl(214.3 31.8% 91.4%);
          background: white;
          color: hsl(215.4 16.3% 46.9%);
          transition: all 0.3s $transition-bounce;
          cursor: pointer;
          overflow: hidden;
          box-shadow: 0 1px 2px hsl(0 0% 0% / 0.02),
          0 2px 4px hsl(0 0% 0% / 0.01);
          transform-style: preserve-3d;
          will-change: transform, box-shadow;

          .icon-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            transition: all 0.3s $transition-bounce;
            transform-style: preserve-3d;
            will-change: transform;
          }

          svg {
            stroke-width: 2;
            transition: all 0.3s $transition-bounce;
            filter: drop-shadow(0 1px 1px hsl(0 0% 0% / 0.03));
            width: 16px;
            height: 16px;
          }

          &::before {
            content: '';
            position: absolute;
            inset: 0;
            background: linear-gradient(135deg,
                    hsl(0 0% 100% / 0.1),
                    transparent
            );
            opacity: 0;
            transition: opacity 0.3s ease;
          }

          &.approve-btn {
            &:hover {
              transform: translateY(-1px) translateZ(5px);
              background: hsl(142.1 76.2% 36.3% / 0.05);
              color: hsl(142.1 76.2% 36.3%);
              border-color: hsl(142.1 76.2% 36.3% / 0.2);
              box-shadow: 0 2px 4px hsl(142.1 76.2% 36.3% / 0.1),
              0 4px 8px hsl(142.1 76.2% 36.3% / 0.1);

              &::before {
                opacity: 1;
              }

              .icon-wrapper {
                transform: scale(1.1) translateZ(2px);
              }
            }

            &.active {
              background: $success-gradient;
              border-color: transparent;
              color: white;
              transform: translateY(0) translateZ(2px);
              box-shadow: 0 1px 2px hsl(142.1 76.2% 36.3% / 0.2),
              0 2px 4px hsl(142.1 76.2% 36.3% / 0.2);

              svg {
                filter: drop-shadow(0 1px 1px hsl(0 0% 0% / 0.1));
              }
            }
          }

          &.reject-btn {
            &:hover {
              transform: translateY(-1px) translateZ(5px);
              background: hsl(0 84.2% 60.2% / 0.05);
              color: hsl(0 84.2% 60.2%);
              border-color: hsl(0 84.2% 60.2% / 0.2);
              box-shadow: 0 2px 4px hsl(0 84.2% 60.2% / 0.1),
              0 4px 8px hsl(0 84.2% 60.2% / 0.1);

              &::before {
                opacity: 1;
              }

              .icon-wrapper {
                transform: scale(1.1) translateZ(2px);
              }
            }

            &.active {
              background: $error-gradient;
              border-color: transparent;
              color: white;
              transform: translateY(0) translateZ(2px);
              box-shadow: 0 1px 2px hsl(0 84.2% 60.2% / 0.2),
              0 2px 4px hsl(0 84.2% 60.2% / 0.2);

              svg {
                filter: drop-shadow(0 1px 1px hsl(0 0% 0% / 0.1));
              }
            }
          }
        }
      }
    }

    .review-complete {
      margin-top: 4rem;
      padding: 2rem;
      display: flex;
      justify-content: center;
      animation: fadeIn 0.5s ease;

      .proceed-btn {
        display: flex;
        align-items: center;
        gap: 0.875rem;
        padding: 1rem 2rem;
        background: linear-gradient(135deg, #e4b17a, #c99b69);
        color: white;
        border-radius: 60px;
        font-weight: 500;
        font-size: 0.9375rem;
        letter-spacing: -0.01em;
        transition: all 0.3s $transition-bounce;
        box-shadow: 0 4px 12px rgba(228, 177, 122, 0.2);
        text-decoration: none;
        position: relative;
        overflow: hidden;

        &::before {
          content: '';
          position: absolute;
          inset: 0;
          background: linear-gradient(
                          135deg,
                          hsl(0 0% 100% / 0.2),
                          hsl(0 0% 100% / 0.05)
          );
          opacity: 0;
          transition: opacity 0.3s ease;
        }

        &:hover {
          transform: translateY(-2px);
          box-shadow: 0 8px 24px rgba(228, 177, 122, 0.3);

          &::before {
            opacity: 1;
          }

          svg:last-child {
            transform: translateX(4px);
          }
        }

        svg {
          width: 18px;
          height: 18px;
          transition: transform 0.3s ease;
        }

        svg:last-child {
          margin-left: 0.25rem;
        }
      }
    }

    .no-questions {
      flex-direction: column;
      gap: 1rem;
      padding: 3rem;
      text-align: center;
      background: rgba(255, 255, 255, 0.98);
      border-radius: 1rem;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);

      svg {
        color: #6b7280;
        margin-bottom: 1rem;
      }

      p {
        margin: 0 0 0.5rem;
        font-size: 1.125rem;
        font-weight: 500;
        color: #374151;
      }

      .help-text {
        color: #6b7280;
        font-size: 0.875rem;
      }
    }
  }

  .processing-state {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    color: #64748b;

    p {
      font-size: 0.875rem;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    filter: brightness(1);
  }
  50% {
    transform: scale(1.05);
    filter: brightness(1.1);
  }
  100% {
    transform: scale(1);
    filter: brightness(1);
  }
}

.header-content {
  .header-subtitle {
    color: #64748b;
    font-size: 0.9375rem;
    margin-top: 0.5rem;
    animation: fadeIn 0.5s ease;
  }
}

.help-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.625rem 1.25rem;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border: 1px solid rgba(226, 232, 240, 0.8);
  border-radius: 0.75rem;
  color: #64748b;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    background: #f8fafc;
    color: #e4b17a;
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(228, 177, 122, 0.1);
  }
}

.upload-tip {
  margin-top: 0.5rem;
  padding: 0.75rem 1rem;
  background: rgba(228, 177, 122, 0.1);
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  // gap: 0.75rem;
  color: #e4b17a;
  font-size: 0.875rem;
  animation: fadeIn 0.5s ease;

  span {
    margin-bottom: 0px;
  }

  svg {
    animation: sparkle 1.5s ease-in-out infinite;
  }
}

@keyframes sparkle {
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.8;
    filter: brightness(1.2);
  }
}

.bounce {
  animation: bounce 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-12px);
  }
}

.question-text {
  color: #334155;
  font-size: 1rem;
  line-height: 1.6;
  margin: 1rem 0;
}

.option-item {
  &.correct {
    background: rgba(34, 197, 94, 0.1);
    border-color: rgba(34, 197, 94, 0.2);

    .option-letter {
      color: #16a34a;
      font-weight: 600;
    }

    .correct-mark {
      color: #16a34a;
    }
  }
}

.no-questions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  color: #94a3b8;
  animation: fadeIn 0.5s ease;

  svg {
    color: #cbd5e1;
  }

  p {
    font-size: 0.9375rem;
  }
}

.proceed-btn {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 1rem 2rem;
  background: linear-gradient(135deg, #e4b17a, #c99b69);
  color: white;
  border-radius: 1rem;
  font-weight: 500;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 4px 12px rgba(228, 177, 122, 0.2);
  text-decoration: none;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.05));
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 24px rgba(228, 177, 122, 0.3);

    &::before {
      opacity: 1;
    }

    svg:last-child {
      transform: translateX(4px);
    }
  }

  svg:last-child {
    transition: transform 0.3s ease;
  }
}

@keyframes fadeInScale {
  0% {
    opacity: 0;
    transform: scale(0.98) translateY(10px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes approveButtonAnimation {
  0% {
    transform: scale(1) translateZ(0);
  }
  20% {
    transform: scale(0.95) translateZ(-2px);
  }
  40% {
    transform: scale(1.1) translateZ(8px) rotate(1deg);
  }
  60% {
    transform: scale(1.05) translateZ(4px) rotate(-0.5deg);
  }
  80% {
    transform: scale(0.98) translateZ(2px);
  }
  100% {
    transform: scale(1) translateZ(0);
  }
}

@keyframes rejectButtonAnimation {
  0% {
    transform: scale(1) translateZ(0) rotate(0);
  }
  20% {
    transform: scale(0.95) translateZ(-2px) rotate(-1deg);
  }
  40% {
    transform: scale(1.1) translateZ(8px) rotate(4deg);
  }
  60% {
    transform: scale(1.05) translateZ(4px) rotate(-2deg);
  }
  80% {
    transform: scale(0.98) translateZ(2px) rotate(0.5deg);
  }
  100% {
    transform: scale(1) translateZ(0) rotate(0);
  }
}

@keyframes approvalCardAnimation {
  0% {
    transform: translateY(0) translateZ(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02),
    0 4px 8px rgba(0, 0, 0, 0.02);
  }
  40% {
    transform: translateY(-4px) translateZ(10px);
    box-shadow: 0 8px 16px hsl(142.1 76.2% 36.3% / 0.1),
    0 16px 32px hsl(142.1 76.2% 36.3% / 0.1);
  }
  100% {
    transform: translateY(-2px) translateZ(5px);
    box-shadow: 0 4px 8px hsl(142.1 76.2% 36.3% / 0.1),
    0 8px 16px hsl(142.1 76.2% 36.3% / 0.1);
  }
}

@keyframes rejectionCardAnimation {
  0% {
    transform: translateY(0) translateZ(0) rotate(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02),
    0 4px 8px rgba(0, 0, 0, 0.02);
  }
  25% {
    transform: translateY(-2px) translateZ(5px) rotate(-0.5deg);
    box-shadow: 0 4px 8px hsl(0 84.2% 60.2% / 0.1),
    0 8px 16px hsl(0 84.2% 60.2% / 0.1);
  }
  50% {
    transform: translateY(-4px) translateZ(10px) rotate(0.5deg);
    box-shadow: 0 8px 16px hsl(0 84.2% 60.2% / 0.1),
    0 16px 32px hsl(0 84.2% 60.2% / 0.1);
  }
  75% {
    transform: translateY(-3px) translateZ(8px) rotate(-0.25deg);
    box-shadow: 0 6px 12px hsl(0 84.2% 60.2% / 0.1),
    0 12px 24px hsl(0 84.2% 60.2% / 0.1);
  }
  100% {
    transform: translateY(-2px) translateZ(5px) rotate(0);
    box-shadow: 0 4px 8px hsl(0 84.2% 60.2% / 0.1),
    0 8px 16px hsl(0 84.2% 60.2% / 0.1);
  }
}

.animate-approval {
  animation: approveButtonAnimation 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.animate-rejection {
  animation: rejectButtonAnimation 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.animate-approval-card {
  animation: approvalCardAnimation 0.6s cubic-bezier(0.2, 0.8, 0.2, 1) forwards;
}

.animate-rejection-card {
  animation: rejectionCardAnimation 0.6s cubic-bezier(0.2, 0.8, 0.2, 1) forwards;
}

.action-buttons {
  display: flex;
  align-items: center;
  gap: 0.75rem;

  .edit-btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 0.5rem;
    border: 1px solid hsl(214.3 31.8% 91.4%);
    background: white;
    color: hsl(215.4 16.3% 46.9%);
    transition: all 0.3s $transition-bounce;
    cursor: pointer;
    overflow: hidden;
    box-shadow: 0 1px 2px hsl(0 0% 0% / 0.02),
    0 2px 4px hsl(0 0% 0% / 0.01);

    &:hover {
      transform: translateY(-1px);
      background: hsl(221.2 83.2% 53.3% / 0.05);
      color: hsl(221.2 83.2% 53.3%);
      border-color: hsl(221.2 83.2% 53.3% / 0.2);
      box-shadow: 0 2px 4px hsl(221.2 83.2% 53.3% / 0.1),
      0 4px 8px hsl(221.2 83.2% 53.3% / 0.1);
    }

    .icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      transition: all 0.3s $transition-bounce;
    }
  }
}

.edit-actions {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    transition: all 0.3s $transition-bounce;
    cursor: pointer;

    .icon-wrapper {
      display: flex;
      align-items: center;
      gap: 0.375rem;
    }

    &.save-btn {
      background: $success-gradient;
      color: white;
      border: none;
      box-shadow: 0 1px 2px hsl(142.1 76.2% 36.3% / 0.2),
      0 2px 4px hsl(142.1 76.2% 36.3% / 0.2);

      &:hover {
        transform: translateY(-1px);
        box-shadow: 0 2px 4px hsl(142.1 76.2% 36.3% / 0.2),
        0 4px 8px hsl(142.1 76.2% 36.3% / 0.2);
      }
    }

    &.cancel-btn {
      background: white;
      color: hsl(215.4 16.3% 46.9%);
      border: 1px solid hsl(214.3 31.8% 91.4%);

      &:hover {
        background: hsl(210 40% 98%);
        color: hsl(0 84.2% 60.2%);
        border-color: hsl(0 84.2% 60.2% / 0.2);
      }
    }
  }
}

.edit-form {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    label {
      font-size: 0.875rem;
      font-weight: 500;
      color: hsl(215.4 16.3% 46.9%);
    }

    textarea {
      width: 100%;
      padding: 0.75rem;
      border: 1px solid hsl(214.3 31.8% 91.4%);
      border-radius: 0.5rem;
      background: white;
      font-size: 0.9375rem;
      line-height: 1.6;
      color: hsl(222.2 47.4% 11.2%);
      resize: vertical;
      min-height: 80px;
      transition: all 0.2s ease;

      &:focus {
        outline: none;
        border-color: hsl(221.2 83.2% 53.3%);
        box-shadow: 0 0 0 3px hsl(221.2 83.2% 53.3% / 0.1),
        0 1px 2px hsl(0 0% 0% / 0.02);
      }

      &::placeholder {
        color: hsl(215.4 16.3% 46.9% / 0.5);
      }
    }
  }

  .subquestions-edit {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding-top: 0.5rem;
    border-top: 1px dashed hsl(214.3 31.8% 91.4%);

    .subquestion-edit {
      background: hsl(210 40% 98%);
      padding: 1rem;
      border-radius: 0.75rem;
      border: 1px solid hsl(214.3 31.8% 91.4%);
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
}

@keyframes glow {
  0%, 100% {
    box-shadow: 0 4px 24px -6px rgba(0, 0, 0, 0.3),
    0 12px 40px -8px rgba(0, 0, 0, 0.25),
    inset 0 1px 1px rgba(255, 255, 255, 0.1),
    inset 0 -1px 1px rgba(0, 0, 0, 0.1);
  }
  50% {
    box-shadow: 0 4px 24px -6px rgba(0, 0, 0, 0.3),
    0 12px 40px -8px rgba(0, 0, 0, 0.25),
    0 0 20px rgba(228, 177, 122, 0.2),
    inset 0 1px 1px rgba(255, 255, 255, 0.1),
    inset 0 -1px 1px rgba(0, 0, 0, 0.1);
  }
}