body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 5px rgba(251, 146, 60, 0.3),
      0 0 10px rgba(251, 146, 60, 0.2),
      0 0 15px rgba(251, 146, 60, 0.1);
  }

  50% {
    box-shadow: 0 0 10px rgba(251, 146, 60, 0.5),
      0 0 20px rgba(251, 146, 60, 0.3),
      0 0 30px rgba(251, 146, 60, 0.2);
  }

  100% {
    box-shadow: 0 0 5px rgba(251, 146, 60, 0.3),
      0 0 10px rgba(251, 146, 60, 0.2),
      0 0 15px rgba(251, 146, 60, 0.1);
  }
}

.animate-glow {
  animation: glow 1s ease-in-out;
}

@keyframes glow-text {
  0% {
    text-shadow: 0 0 4px rgba(251, 146, 60, 0.4),
      0 0 8px rgba(251, 146, 60, 0.3),
      0 0 12px rgba(251, 146, 60, 0.2);
    color: #fb923c;
  }

  50% {
    text-shadow: 0 0 8px rgba(251, 146, 60, 0.8),
      0 0 16px rgba(251, 146, 60, 0.6),
      0 0 24px rgba(251, 146, 60, 0.4);
    color: #ea580c;
  }

  100% {
    text-shadow: 0 0 4px rgba(251, 146, 60, 0.4),
      0 0 8px rgba(251, 146, 60, 0.3),
      0 0 12px rgba(251, 146, 60, 0.2);
    color: #fb923c;
  }
}

.animate-glow-text {
  animation: glow-text 1s ease-in-out;
}

@keyframes loading-bar {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.animate-loading-bar {
  animation: loading-bar 2s ease-in-out infinite;
}