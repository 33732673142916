@keyframes loading-bar {
  0% {
    transform: translateX(-100%);
    background-position: 0% 50%;
  }
  50% {
    transform: translateX(0%);
    background-position: 100% 50%;
  }
  100% {
    transform: translateX(100%);
    background-position: 0% 50%;
  }
}

@keyframes group-loading-bar {
  0% {
    transform: translateX(-100%);
    background-position: 0% 50%;
  }
  25% {
    transform: translateX(-25%);
    background-position: 50% 50%;
  }
  50% {
    transform: translateX(0%);
    background-position: 100% 50%;
  }
  75% {
    transform: translateX(25%);
    background-position: 50% 50%;
  }
  100% {
    transform: translateX(100%);
    background-position: 0% 50%;
  }
}

.loading-container {
  .loading-bar {
    @apply w-48 h-1 bg-gray-100 rounded-full overflow-hidden relative;

    .bar {
      @apply h-full absolute inset-0;

      &.default {
        background: linear-gradient(90deg, #E5E7EB, #D1D5DB, #E5E7EB);
        background-size: 200% 100%;
        animation: loading-bar 2s ease-in-out infinite;
      }

      &.group {
        background: linear-gradient(90deg, rgb(253, 248, 243), rgb(228, 177, 122), rgb(249, 237, 226));
        background-size: 200% 100%;
        animation: group-loading-bar 4s cubic-bezier(0.4, 0, 0.2, 1) infinite;
        opacity: 0.8;

        &::after {
          content: '';
          @apply absolute inset-0;
          background: inherit;
          filter: blur(4px);
          opacity: 0.5;
        }
      }
    }
  }
}